<template>
  <Card
    displayControls
    title="Pricing Product"
    pathTo
    :location="goBackAndClearLastRoute"
  >
    <ValidationObserver
      class="d-flex flex flex-column justify-space-between"
      ref="observer"
      v-slot="{ invalid, changed }"
    >
      <v-card class="px-10 pt-7 flex" flat>
        <h1 class="midnightGreen--text">
          Update Product
          <br />Pricing
        </h1>
        <v-form v-if="pricing">
          <v-row>
            <v-col cols="5">
              <ValidationProvider
                name="Min Price"
                rules="numeric"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model.number="formItems.min_price"
                  class="input"
                  type="text"
                  label="Min Price in KES"
                  :error-messages="errors"
                  :placeholder="pricing.min_price"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="2">
              <p class="mt-4 font-weight-medium text-center to">to</p>
            </v-col>
            <v-col cols="5">
              <ValidationProvider
                name="Max Price"
                rules="numeric"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model.number="formItems.max_price"
                  class="input"
                  type="text"
                  label="Max Price in KES"
                  :error-messages="errors"
                  :placeholder="pricing.max_price"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
          <ValidationProvider
            name="Quantity"
            :rules="`numeric|max_value:${maxQuantity}`"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model.number="formItems.min_unit"
              :label="
                `Minimum Order Quantity ${retrieveCurrentMaxQuantityAvailable}`
              "
              :error-messages="errors"
              :placeholder="pricing.min_unit"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            name="Cost of Delivery"
            rules="min:3"
            v-slot="{ errors }"
          >
            <v-select
              v-model="formItems.delivery_cost"
              label="Cost of Delivery"
              :items="deliveryCosts"
              dense
              :placeholder="pricing.delivery_cost"
              :error-messages="errors"
              class="input"
            ></v-select>
          </ValidationProvider>
        </v-form>
      </v-card>
      <v-card flat class="d-flex align-items-center px-10 pb-5">
        <v-icon color="#35A19E">delete</v-icon>
        <span
          @click="
            confirmationAlert({
              productId: $route.params.productId,
              pricingId: pricing.id,
              currentRoute: $route.fullPath,
              propertyToDelete: 'pricing'
            })
          "
          class="midnightGreen--text delete--button text-decoration-underline text-capitalize text-h6 font-weight-normal"
          >Delete Pricing</span
        >
      </v-card>
      <BaseButton
        buttonColor="blueGreen"
        textColor="white"
        :loading="buttonLoading"
        :invalid="isUpdated(invalid, changed)"
        @click.native="submit"
        >Save</BaseButton
      >
    </ValidationObserver>
  </Card>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

import Card from "@/components/Card";
import BaseButton from "@/components/BaseButton";
import { filterObjectMixin } from "@/mixins/filterObjectMixin";
import { clearLastRouteMixin } from "@/mixins/clearLastRouteMixin";
import { checkRedirectRouteMixin } from "@/mixins/checkRedirectRouteMixin";

export default {
  name: "ProductPricingUpdate",
  mixins: [filterObjectMixin, clearLastRouteMixin, checkRedirectRouteMixin],
  props: {
    redirectToRoute: String
  },
  components: {
    Card,
    BaseButton
  },
  data() {
    return {
      formItems: {
        min_price: "",
        max_price: "",
        min_unit: "",
        delivery_cost: ""
      },
      categories: [
        "Agriculture",
        "Beauty",
        "Electronics",
        "Fashion",
        "Gaming",
        "Health",
        "Finance",
        "Sporting Goods",
        "Materials",
        "Phones & Tablets",
        "Computing",
        "Other"
      ],
      deliveryCosts: [
        "Free in all areas",
        "Free in some areas",
        "User pays for delivery"
      ]
    };
  },
  async created() {
    await this.setIsLoading();
    await this.fetchPricingById(this.$route.params.pricingId);
    await this.setLoaded();
    this.checkRedirectRoute();
  },
  computed: {
    ...mapState(["pricing", "redirectRoute", "buttonLoading"]),
    maxQuantity() {
      if (this.pricing.quantity) {
        return this.pricing.quantity;
      }

      return Number.MAX_VALUE;
    },
    retrieveCurrentMaxQuantityAvailable() {
      if (this.pricing.quantity && this.pricing.unit) {
        return `(Available Quantity: ${this.pricing.quantity} ${this.pricing.unit})`;
      }

      return "";
    }
  },
  methods: {
    ...mapActions(["fetchPricingById", "updatePricing", "confirmationAlert"]),
    ...mapMutations([
      "setIsLoading",
      "setLoaded",
      "setRedirectRoute",
      "filterRedirectRoute",
      "setButtonLoading",
      "setButtonLoaded"
    ]),
    isUpdated(invalid, changed) {
      if (!invalid && changed) {
        return false;
      }

      return true;
    },
    submit() {
      this.setButtonLoading();

      const filteredValues = this.filterEmptyFormValues(this.formItems);

      if (this.objectIsFilled(filteredValues)) {
        this.updatePricing({
          pricingId: this.$route.params.pricingId,
          productId: this.$route.params.productId,
          companyId: this.$route.query.company,
          currentRoute: this.$route.fullPath,
          data: filteredValues
        });
      }

      this.setButtonLoaded();
    }
  }
};
</script>
