var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Card',{attrs:{"displayControls":"","title":_vm.product.company_name,"editable":_vm.mine ? (_vm.editable ? false : true) : false,"editMessage":"Edit product","to":("/product/" + (_vm.$route.params.id) + "/delete?company=" + (this.$route.query.company)),"pathTo":"","location":_vm.redirectToLocationCompanyOrDashboard}},[_c('ProductImage',{attrs:{"product":_vm.product}}),_c('v-list',{staticClass:"px-6 flex",attrs:{"three-line":"","nav":""}},[_c('v-radio-group',{model:{value:(_vm.prices),callback:function ($$v) {_vm.prices=$$v},expression:"prices"}},_vm._l((_vm.getPricings),function(price){return _c('v-list-item-group',{key:price.id},[_c('v-list-item',{staticClass:"cursor-default",attrs:{"ripple":false,"inactive":""}},[(_vm.deletable)?_c('v-radio',{attrs:{"color":"blueGreen","value":price.id}}):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6 font-weight-normal blueGreen--text"},[_vm._v(_vm._s(price.min_price)+" KES - "+_vm._s(price.max_price)+" KES ")]),_c('v-list-item-subtitle',[_vm._v(" per "+_vm._s(_vm._f("lowercase")(price.unit))+" ● Min. Order "+_vm._s(price.min_unit)+" "+_vm._s(_vm._f("lowercase")(price.unit))+" ")]),_c('v-list-item-title',{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(price.delivery_cost))])],1),(_vm.deletable)?_c('v-icon',{attrs:{"disabled":_vm.prices !== price.id,"size":"30","color":"blueGreen"},on:{"click":function($event){return _vm.confirmationAlert({
                productId: _vm.$route.params.id,
                pricingId: price.id,
                sameRoute: true,
                propertyToDelete: 'pricing'
              })}}},[_vm._v("mdi-delete-forever")]):_vm._e()],1),_c('v-divider',{attrs:{"inset":""}})],1)}),1)],1),(!_vm.hideImages && _vm.productImages.length > 1)?_c('v-list',{staticClass:"px-6"},[_c('v-list-item-title',{staticClass:"text-h6 midnight--green pb-3"},[_vm._v("Product Images")]),_c('v-list-item',{staticClass:"d-flex overflow-x-auto"},_vm._l((_vm.productImages),function(image){return _c('v-list-item-group',{key:image.id},[_c('v-list-item-avatar',{attrs:{"size":"100"}},[_c('v-img',{attrs:{"src":image.url}})],1)],1)}),1),_c('v-divider',{attrs:{"inset":""}})],1):_vm._e(),(!_vm.disableInterestButton && _vm.mine && _vm.productInterests.length)?_c('BaseButton',{attrs:{"buttonColor":"blueGreen","textColor":"white","to":{
      path: '/interests-dashboard',
      query: {
        product: _vm.$route.params.id,
        company: _vm.$route.query.company
      }
    }}},[_vm._v("Interests")]):_vm._e(),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }