<template>
  <BackgroundFixed>
    <v-card flat class="px-6">
      <v-card-title class="text-h4 midnightGreen--text"
        >Verify your email to finish <br />
        your registration</v-card-title
      >
      <v-btn
        width="100%"
        class="text-h6"
        large
        color="#ff5666"
        dark
        @click="verifyEmail()"
        >Verify</v-btn
      >
    </v-card>
  </BackgroundFixed>
</template>

<script>
import { mapActions } from "vuex";

import BackgroundFixed from "@/components/BackgroundFixed";

export default {
  name: "VerifyAccount",
  components: {
    BackgroundFixed
  },
  data() {
    return {
      queryString: ""
    };
  },
  mounted() {
    this.fetchTokenFromUrl();
  },
  methods: {
    ...mapActions(["verifyEmail", "fetchTokenFromUrl"])
  }
};
</script>

<style scoped>
.v-card__title {
  word-break: normal;
  text-align: center;
}
</style>
