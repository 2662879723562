var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.registered)?_c('Success'):_c('Card',{attrs:{"cardWidth":"50%"}},[_c('ValidationObserver',{ref:"obs",staticClass:"px-10 pt-10 pb-5",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card-title',{staticClass:"px-0 pb-10 midnightGreen--text text-h3"},[_vm._v(" Personal "),_c('br'),_vm._v("Info ")]),_c('v-form',[_c('ValidationProvider',{attrs:{"name":"Name","mode":"eager","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"type":"text","error-messages":errors,"label":"Name","required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Email","mode":"eager","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"type":"email","error-messages":errors,"label":"Email","required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"ID/Passport","mode":"eager","rules":"required|min:7|alpha_num"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"type":"text","name":"ID/Passport","error-messages":errors,"label":"ID/Passport","required":""},model:{value:(_vm.id_passport_no),callback:function ($$v) {_vm.id_passport_no=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"id_passport_no"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Password","vid":"confirm","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password',"error-messages":errors,"label":"Password","required":""},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Confirmation password","rules":"required|min:5|confirmed:confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"append-icon":_vm.showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showConfirmPassword ? 'text' : 'password',"error-messages":errors,"label":"Confirm Password","required":""},on:{"click:append":function($event){_vm.showConfirmPassword = !_vm.showConfirmPassword}},model:{value:(_vm.passwordConfirmed),callback:function ($$v) {_vm.passwordConfirmed=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"passwordConfirmed"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":{ required: { allowFalse: false } }}},[_c('div',[_c('v-checkbox',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-2 text--secondary v-btn--uppercase-disable text-wrap agree-btn",attrs:{"text":""},on:{"click":function($event){_vm.dialog = true}}},on),[_vm._v("I agree to Jukua's terms and conditions")])]}}],null,true)},[_vm._v(" Click to read our Terms and Conditions. "),_c('br'),_vm._v("Note: You cannot proceed if you do not Agree. ")])]},proxy:true}],null,true),model:{value:(_vm.checkbox),callback:function ($$v) {_vm.checkbox=$$v},expression:"checkbox"}})],1),_c('v-dialog',{attrs:{"width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-text',[_c('TermsandConditions',{staticClass:"pt-4"})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"font-weight-bold px-5",attrs:{"color":"green darken-1","text":""},on:{"click":function($event){(_vm.dialog = false), (_vm.checkbox = false)}}},[_vm._v("Disagree")]),_c('v-btn',{staticClass:"font-weight-bold",attrs:{"color":"green darken-1","text":""},on:{"click":function($event){(_vm.dialog = false), (_vm.checkbox = true)}}},[_vm._v("Agree")])],1)],1)],1)],1)],1),_c('v-card',{staticClass:"text-h6 d-flex justify-space-between align-center signUp pt-5 pb-3",attrs:{"tile":"","flat":""}},[_c('v-card-text',{staticClass:"pa-0 text-h6 midnightGreen--text"},[_vm._v("Sign Up")]),_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","large":"","color":"primary","disabled":invalid},on:{"click":function($event){return _vm.registration({ name: _vm.name, email: _vm.email, id_passport_no: _vm.id_passport_no, password: _vm.password })}}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-arrow-right")])],1)],1),_c('router-link',{staticClass:"text-h6 text-underline-offset",attrs:{"to":"/login"}},[_vm._v("Sign in")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }