var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Card',{attrs:{"displayControls":"","closeable":"","title":"Edit Company"}},[_c('ValidationObserver',{ref:"obs",staticClass:"flex d-flex flex-column justify-space-between",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var changed = ref.changed;
return [_c('v-card',{staticClass:"px-8 pt-5",attrs:{"flat":""}},[_c('ImageInput'),(_vm.company)?_c('v-form',[_c('ValidationProvider',{attrs:{"name":"Company","rules":"min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Company Name","placeholder":_vm.company.name,"required":""},model:{value:(_vm.formItems.name),callback:function ($$v) {_vm.$set(_vm.formItems, "name", $$v)},expression:"formItems.name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Company type","rules":"min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.items,"label":"Company type","placeholder":_vm.company.type,"required":""},model:{value:(_vm.formItems.type),callback:function ($$v) {_vm.$set(_vm.formItems, "type", $$v)},expression:"formItems.type"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Company Email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-1",attrs:{"error-messages":errors,"label":"Company Email","placeholder":_vm.company.email,"required":""},model:{value:(_vm.formItems.email),callback:function ($$v) {_vm.$set(_vm.formItems, "email", $$v)},expression:"formItems.email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Business registration number","mode":"eager","rules":"min:6|alpha_num"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"type":"text","error-messages":errors,"label":"Business Registration Number","placeholder":_vm.company.business_regno,"required":""},model:{value:(_vm.formItems.business_regno),callback:function ($$v) {_vm.$set(_vm.formItems, "business_regno", $$v)},expression:"formItems.business_regno"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Business KRA Pin","rules":"min:11|max:11|alpha_num"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-1",attrs:{"error-messages":errors,"label":"Business KRA Pin","placeholder":_vm.company.kra_pin,"required":""},model:{value:(_vm.formItems.kra_pin),callback:function ($$v) {_vm.$set(_vm.formItems, "kra_pin", $$v)},expression:"formItems.kra_pin"}})]}}],null,true)}),_c('vue-tel-input',{staticClass:"none",attrs:{"defaultCountry":"KE","inputOptions":{
            placeholder: ("Director's number: " + (_vm.company.phone_number))
          }},on:{"validate":_vm.extractPhoneNumber},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}}),_c('ValidationProvider',{attrs:{"name":"Company address","rules":"min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Company address","placeholder":_vm.company.address,"required":""},model:{value:(_vm.formItems.address),callback:function ($$v) {_vm.$set(_vm.formItems, "address", $$v)},expression:"formItems.address"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Company location","rules":"min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Company location","placeholder":_vm.company.physical_location,"required":""},model:{value:(_vm.formItems.physical_location),callback:function ($$v) {_vm.$set(_vm.formItems, "physical_location", $$v)},expression:"formItems.physical_location"}})]}}],null,true)})],1):_vm._e(),_c('v-card',{staticClass:"d-flex align-items-center py-2",attrs:{"flat":""}},[_c('v-icon',{attrs:{"color":"#35A19E"}},[_vm._v("delete")]),_c('span',{staticClass:"midnightGreen--text delete--button text-decoration-underline text-capitalize text-h6 font-weight-normal",on:{"click":function($event){return _vm.confirmationAlert({
              companyId: _vm.$route.params.id,
              propertyToDelete: 'company'
            })}}},[_vm._v("Delete Company")])],1)],1),_c('BaseButton',{attrs:{"invalid":_vm.isValidForm(invalid, changed, _vm.validNumber),"loading":_vm.buttonLoading},nativeOn:{"click":function($event){return _vm.submit($event)}}},[_vm._v("Save")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }