var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.productImages)?_c('v-list',{staticClass:"px-6"},[_c('v-list-item',{staticClass:"d-flex overflow-x-auto"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item-avatar',_vm._g(_vm._b({staticClass:"rounded-0 rounded-lg",attrs:{"size":"100","color":"brown lighten-5"},on:{"click":function($event){return _vm.$router.push({
              name: 'ProductImageUpload',
              params: {
                productId: _vm.$route.params.id,
                redirectToRoute: _vm.$route.fullPath
              },
              query: {
                company: _vm.$route.query.company
              }
            })}}},'v-list-item-avatar',attrs,false),on),[_c('v-icon',{attrs:{"size":"100","color":"#F8B234"}},[_vm._v("mdi-plus")])],1)]}}],null,false,1913852872)},[_c('span',[_vm._v("Add image")])]),_vm._l((_vm.productImages),function(image){return _c('v-list-item-group',{key:image.id},[_c('v-list-item-avatar',{staticClass:"rounded-0 rounded-lg elevation-3",attrs:{"size":"100"}},[_c('v-img',{attrs:{"src":image.url}})],1)],1)})],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }