export const widthMixin = {
  computed: {
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case "sm":
        case "xs":
          return this.smallScreen;
        case "md":
          return this.mediumScreen ?? this.largeScreen;
        default:
          return this.cardWidth ?? this.largeScreen;
      }
    }
  }
};
