import axios from "axios";

import { store } from "@/store";

/**
 * Requires path as defined in jukua doc https://gitlab.com/qhala-dev/jukua.ke/jukua-backend/-/tree/Database#jukua-api and optional standard axios properties
 *
 * @requires path e.g. /auth/login
 */
export const jukuaApi = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true
});

jukuaApi.interceptors.request.use(
  config => {
    const token = store.state.token;
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  error => Promise.reject(error)
);
