<template>
  <v-bottom-sheet v-model="show" persistent hide-overlay>
    <v-sheet min-height="180px" class="d-flex align-center">
      <v-container>
        <v-row>
          <div class="col-md-8">
            <h2 class="midnightGreen--text font-weight-bold">
              We value your privacy
            </h2>
            <p class="midnightGreen--text">
              {{ message }}
              <router-link
                class="blueGreen--text text-decoration-underline"
                v-on:click.native="openModal()"
                to=""
                >More Information</router-link
              >
            </p>
          </div>
          <div class="col-md-4 d-flex justify-center align-center">
            <v-btn
              depressed
              x-large
              color="primary"
              class="text-uppercase midnightGreen--text font-weight-bold px-15"
              @click="setCookieConsent"
              >I Accept</v-btn
            >
          </div>
        </v-row>
      </v-container>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
import Cookies from "js-cookie";

export default {
  name: "CookiePolicy",
  props: ["show"],
  data() {
    return {
      message:
        "We and our partners use technology such as cookies on our site to personalise content, provide social media features and analyze the use of this technology across the web. You can change your mind and change your consent choices at any time by returning to this site."
    };
  },
  methods: {
    setCookieConsent() {
      Cookies.set("cookieConsent", "agree");
      this.$emit("close");
    },
    openModal() {
      this.$emit("showCookieModal");
    }
  }
};
</script>
<style lang="scss"></style>
