<template>
  <BottomCard>
    <template v-slot:button="{ on, attrs }">
      <v-btn
        :disabled="pricingId && !companiesAvailable"
        class="flex py-8 text-h6 text-capitalize midnightGreen--text font-weight-bold"
        v-bind="attrs"
        v-on="on"
        depressed
        small
        color="primary"
        >Express Interest</v-btn
      >
    </template>
    <template v-slot:content>
      <v-card-title class="text-h5 midnightGreen--text font-weight-bold mb-2"
        >Choose Company</v-card-title
      >
      <v-card-subtitle class="text-subtitle-1 midnightGreen--text"
        >Which company would you like to negotiate with?</v-card-subtitle
      >
      <v-card-text class="scroll-height">
        <v-radio-group v-model="companyId" column>
          <v-list-item-group
            v-for="expression in companiesForExpression"
            :key="expression.id"
          >
            <v-radio :value="expression.id" color="blueGreen" class="pa-1">
              <template v-slot:label>
                <v-card flat class="blueGreen--text text-subtitle-1 ">
                  <v-card-text class="pa-2 blueGreen--text text-subtitle-1">{{
                    expression.name
                  }}</v-card-text>
                  <v-card-text class="px-2 py-1"
                    >{{ expression.physical_location }} &#9679;
                    {{ expression.email }}</v-card-text
                  >
                </v-card>
              </template>
            </v-radio>
            <v-divider></v-divider>
          </v-list-item-group>
        </v-radio-group>
      </v-card-text>
      <v-btn
        :class="
          `font-weight-bold py-8 text-h6 text-capitalize midnightGreen--text`
        "
        large
        width="100%"
        color="primary"
        :disabled="!companyId"
        @click="expressInterest({ pricingId, companyId })"
        >Continue</v-btn
      >
    </template>
  </BottomCard>
</template>

<script>
import { mapActions } from "vuex";

import BottomCard from "@/components/BottomCard";

export default {
  name: "ExpressInterestScrollable",
  components: {
    BottomCard
  },
  props: {
    pricingId: [Number, String],
    companiesAvailable: Boolean,
    companiesForExpression: Array
  },
  data() {
    return {
      companyId: ""
    };
  },
  methods: {
    ...mapActions(["expressInterest"])
  }
};
</script>

<style scoped>
.scroll-height {
  height: 20rem;
}
</style>
