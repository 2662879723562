<template>
  <section class="hero">
    <slot />
  </section>
</template>

<script>
export default {
  name: "Banner"
};
</script>

<style scoped>
.hero {
  min-height: 70vh;
  background-image: url("../assets/landingpage-background.png");
  background-position: top right;
  background-repeat: no-repeat;
  background-size: contain;
}
</style>
