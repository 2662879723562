<template>
  <v-footer padless class="footer py-5">
    <v-row no-gutters cols="12" class="pt-5 pb-15">
      <v-col lg="4" md="4" cols="12">
        <v-card flat color="transparent" class="d-flex justify-center my-3">
          <img src="@/assets/logo beta.svg" width="150vh" />
        </v-card>
        <v-card-text class="d-flex justify-center midnightGreen--text">
          Copyright &copy; 2020 Jukua
        </v-card-text>
      </v-col>
      <v-col lg="4" md="4" cols="12">
        <div class="text-center">
          <h3 class="mb-4 text-center font-weight-bold  midnightGreen--text">
            Contact Us
          </h3>

          <p class="mb-1 midnightGreen--text">
            Phone: +254 113 100 563
          </p>
          <p class="mb-1 midnightGreen--text">
            Email: info@jukua.io
          </p>
          <p class="mb-1 midnightGreen--text">
            CMS Africa, Pioneer Plaza, Chania Avenue, Kilimani, Nairobi, Kenya
          </p>
        </div>
      </v-col>
      <v-col lg="4" md="4" cols="12">
        <v-card flat color="transparent">
          <v-card-text
            class="d-flex justify-center font-weight-bold midnightGreen--text text-h6 px-0"
          >
            Connect
          </v-card-text>
          <ul class="footer-nav d-flex justify-center">
            <li>
              <a
                class="midnightGreen--text"
                href="https://www.facebook.com/JukuaPlatform/"
                target="_blank"
              >
                <v-btn x-large class="px-4" dark icon>
                  <v-icon size="50px" color="midnightGreen"
                    >mdi-facebook</v-icon
                  >
                </v-btn>
              </a>
            </li>
            <li>
              <a
                class="midnightGreen--text"
                href="https://twitter.com/JukuaPlatform"
                target="_blank"
              >
                <v-btn x-large class="px-4" dark icon>
                  <v-icon size="50px" color="midnightGreen">mdi-twitter</v-icon>
                </v-btn>
              </a>
            </li>
            <li>
              <a
                class="midnightGreen--text"
                href="https://www.linkedin.com/company/jukua/"
                target="_blank"
              >
                <v-btn x-large class="px-4" dark icon>
                  <v-icon size="50px" color="midnightGreen"
                    >mdi-linkedin</v-icon
                  >
                </v-btn>
              </a>
            </li>
          </ul>
        </v-card>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style lang="scss" scoped>
.footer-nav {
  display: flex;
  list-style: none;
  padding-left: 0;

  li {
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
