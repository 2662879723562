<template>
  <BackgroundFixed>
    <SuccessIcon>
      Check your email for the verification link.
    </SuccessIcon>
  </BackgroundFixed>
</template>

<script>
import BackgroundFixed from "./BackgroundFixed";
import SuccessIcon from "./SuccessIcon";

export default {
  name: "Success",
  components: {
    BackgroundFixed,
    SuccessIcon
  }
};
</script>
