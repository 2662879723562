<template>
  <Card displayControls closeable title="Edit Profile">
    <ValidationObserver
      class="flex d-flex flex-column justify-space-between"
      ref="obs"
      v-slot="{ invalid, changed }"
    >
      <v-card flat class="px-7 pt-6 flex">
        <h2 class="midnightGreen--text">
          Edit Profile
        </h2>
        <ImageInput />
        <v-form class="px-4">
          <h6 class="font-weight-bold midnightGreen--text">
            Change your details
          </h6>
          <ValidationProvider
            name="Name"
            mode="eager"
            rules="min:3|max:36"
            v-slot="{ errors }"
          >
            <v-text-field
              class="input"
              type="text"
              v-model="form.name"
              :error-messages="errors"
              label="Name"
              :placeholder="profile.name"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            name="Email"
            mode="eager"
            rules="email"
            v-slot="{ errors }"
          >
            <v-text-field
              class="input"
              type="email"
              v-model="form.email"
              :error-messages="errors"
              label="Email"
              :placeholder="profile.email"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            name="ID/Passport"
            mode="eager"
            rules="min:7|alpha_num"
            v-slot="{ errors }"
          >
            <v-text-field
              class="input"
              type="text"
              name="ID/Passport"
              v-model.trim="form.id_passport_no"
              :error-messages="errors"
              label="ID/Passport"
              :placeholder="profile.settings.id_passport_no"
            ></v-text-field>
          </ValidationProvider>
          <h6 class="font-weight-bold midnightGreen--text">
            Change your password
          </h6>
          <ValidationProvider
            name="Old password"
            mode="eager"
            rules="min:5|max:36"
            v-slot="{ errors }"
          >
            <v-text-field
              class="input"
              type="password"
              v-model="old_password"
              :error-messages="errors"
              label="Old Password"
            ></v-text-field>
          </ValidationProvider>

          <ValidationProvider
            name="New password"
            vid="confirm"
            mode="eager"
            rules="min:5|max:36"
            v-slot="{ errors }"
          >
            <v-text-field
              class="input"
              type="password"
              v-model="password"
              label="New Password"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            name="Confirm password"
            mode="eager"
            rules="min:5|confirmed:confirm"
            v-slot="{ errors }"
          >
            <v-text-field
              class="input"
              mode="eager"
              type="password"
              v-model="passwordConfirmed"
              :error-messages="errors"
              label="Confirm Password"
            ></v-text-field>
          </ValidationProvider>
        </v-form>
        <v-card flat class="d-flex align-items-center py-2">
          <v-icon color="#35A19E">delete</v-icon>
          <DeleteProfile v-slot="{ attrs, on }">
            <span
              v-bind="attrs"
              v-on="on"
              class="midnightGreen--text delete--button text-decoration-underline text-capitalize text-h6 font-weight-normal"
              >Delete Profile</span
            >
          </DeleteProfile>
        </v-card>
      </v-card>
      <BaseButton
        class="d-flex align-bottom justify-self-end"
        @click.native="submitForUpdate"
        :loading="buttonLoading"
        :invalid="isValidForm(invalid, changed)"
        >Done
      </BaseButton>
    </ValidationObserver>
  </Card>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

import Card from "@/components/Card";
import BaseButton from "@/components/BaseButton";
import DeleteProfile from "@/components/DeleteProfile";
import ImageInput from "@/components/ImageInput";
import { filterObjectMixin } from "@/mixins/filterObjectMixin";

const initialState = () => ({
  form: {
    name: "",
    email: "",
    id_passport_no: ""
  },
  old_password: "",
  password: "",
  passwordConfirmed: ""
});

export default {
  name: "EditProfile",
  mixins: [filterObjectMixin],
  components: {
    Card,
    BaseButton,
    DeleteProfile,
    ImageInput
  },
  data: () => initialState(),
  async created() {
    await this.setIsLoading();
    await this.fetchProfile();
    await this.setLoaded();
  },
  beforeDestroy() {
    this.setPreviewImage(null);
  },
  computed: {
    ...mapState(["profile", "previewImage", "isLoading", "buttonLoading"])
  },
  methods: {
    ...mapActions([
      "changePassword",
      "updateProfile",
      "deleteProfile",
      "uploadFiles",
      "fetchProfile"
    ]),
    ...mapMutations([
      "setPreviewImage",
      "setIsLoading",
      "setLoaded",
      "setButtonLoading",
      "setButtonLoaded"
    ]),
    isValidForm(invalid, changed) {
      if (!invalid && changed) {
        return false;
      }

      if (this.previewImage) {
        return false;
      }

      return true;
    },
    async submitForUpdate() {
      this.setButtonLoading();

      if (this.old_password && this.password) {
        await this.changePassword({
          old_password: this.old_password,
          password: this.password
        });
      }

      const filteredValues = this.filterEmptyFormValues(this.form);

      if (this.objectIsFilled(filteredValues)) {
        await this.updateProfile(filteredValues);

        this.resetData();
      }

      if (this.previewImage) {
        await this.uploadFiles({ id: this.profile.id, category: "user" });
      }

      this.setButtonLoaded();
    },
    resetData() {
      Object.assign(this.$data, initialState());
    }
  }
};
</script>

<style scoped>
.delete--button:hover {
  cursor: pointer;
}
</style>
