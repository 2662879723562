<template>
  <Card
    displayControls
    :title="product.company_name"
    :editable="mine ? (editable ? false : true) : false"
    editMessage="Edit product"
    :to="
      `/product/${$route.params.id}/delete?company=${this.$route.query.company}`
    "
    pathTo
    :location="redirectToLocationCompanyOrDashboard"
  >
    <ProductImage :product="product" />
    <v-list class="px-6 flex" three-line nav>
      <v-radio-group v-model="prices">
        <v-list-item-group v-for="price in getPricings" :key="price.id">
          <v-list-item class="cursor-default" :ripple="false" inactive>
            <v-radio v-if="deletable" color="blueGreen" :value="price.id" />
            <v-list-item-content>
              <v-list-item-title
                class="text-h6 font-weight-normal blueGreen--text"
                >{{ price.min_price }} KES - {{ price.max_price }} KES
              </v-list-item-title>
              <v-list-item-subtitle>
                per {{ price.unit | lowercase }} &#9679; Min. Order
                {{ price.min_unit }} {{ price.unit | lowercase }}
              </v-list-item-subtitle>
              <v-list-item-title class="text-subtitle-1">
                {{ price.delivery_cost }}</v-list-item-title
              >
            </v-list-item-content>
            <v-icon
              v-if="deletable"
              @click="
                confirmationAlert({
                  productId: $route.params.id,
                  pricingId: price.id,
                  sameRoute: true,
                  propertyToDelete: 'pricing'
                })
              "
              :disabled="prices !== price.id"
              size="30"
              color="blueGreen"
              >mdi-delete-forever</v-icon
            >
          </v-list-item>
          <v-divider inset></v-divider>
        </v-list-item-group>
      </v-radio-group>
    </v-list>
    <v-list v-if="!hideImages && productImages.length > 1" class="px-6">
      <v-list-item-title class="text-h6 midnight--green pb-3"
        >Product Images</v-list-item-title
      >
      <v-list-item class="d-flex overflow-x-auto">
        <v-list-item-group v-for="image in productImages" :key="image.id">
          <v-list-item-avatar size="100">
            <v-img :src="image.url"></v-img>
          </v-list-item-avatar>
        </v-list-item-group>
      </v-list-item>
      <v-divider inset></v-divider>
    </v-list>
    <BaseButton
      v-if="!disableInterestButton && mine && productInterests.length"
      buttonColor="blueGreen"
      textColor="white"
      :to="{
        path: '/interests-dashboard',
        query: {
          product: $route.params.id,
          company: $route.query.company
        }
      }"
      >Interests</BaseButton
    >
    <slot />
  </Card>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";

import Card from "@/components/Card";
import ProductImage from "@/components/ProductImage";
import BaseButton from "@/components/BaseButton";
import { checkRedirectRouteMixin } from "@/mixins/checkRedirectRouteMixin";
import { clearLastRouteMixin } from "@/mixins/clearLastRouteMixin";

export default {
  name: "Product",
  mixins: [checkRedirectRouteMixin, clearLastRouteMixin],
  components: {
    Card,
    BaseButton,
    ProductImage
  },
  props: {
    editable: Boolean,
    disableInterestButton: Boolean,
    deletable: Boolean,
    hideImages: Boolean,
    pathTo: Boolean,
    location: Function,
    redirectToRoute: String
  },
  data() {
    return {
      mine: false,
      displayInterest: true,
      prices: null
    };
  },
  async created() {
    await this.setIsLoading();
    await this.fetchProductById(this.$route.params.id);
    await this.fetchPricingByProductId(this.$route.params.id);
    await this.fetchInterestsByProductId(this.$route.params.id);
    await this.fetchProductImagesById(this.$route.params.id);
    this.checkProductOwnership();
    await this.setLoaded();
    this.checkRedirectRoute();
  },
  computed: {
    ...mapState([
      "product",
      "productInterests",
      "productImages",
      "redirectRoute"
    ]),
    ...mapGetters(["getPricings"])
  },
  methods: {
    ...mapActions([
      "fetchPricingByProductId",
      "fetchProductById",
      "fetchInterestsByProductId",
      "fetchProductImagesById",
      "confirmationAlert"
    ]),
    ...mapMutations([
      "setIsLoading",
      "setLoaded",
      "setRedirectRoute",
      "filterRedirectRoute"
    ]),
    checkProductOwnership() {
      if (this.product?.company_owner === "Y") {
        this.mine = true;
        return;
      }

      this.mine = false;
    },
    redirectToLocationCompanyOrDashboard() {
      if (this.location) {
        return this.location();
      }

      if (Object.keys(this.redirectRoute).length) {
        return this.goBackAndClearLastRoute();
      }

      return this.$router.push(`/company/${this.$route.query.company}`);
    }
  }
};
</script>

<style scoped>
.cursor-default {
  cursor: default;
}
</style>
