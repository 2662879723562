var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Card',{attrs:{"displayControls":"","title":"Company Registration"}},[_c('ValidationObserver',{ref:"obs",staticClass:"main-container d-flex flex-column justify-content-between",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"px-7 pt-7"},[_c('h1',{staticClass:"midnightGreen--text"},[_vm._v(" Company "),_c('br'),_vm._v("Info ")]),_c('v-form',{staticClass:"offset-x"},[_c('ValidationProvider',{attrs:{"name":"Company name","mode":"eager","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"type":"text","id":"name","error-messages":errors,"label":"Company Name","required":""},model:{value:(_vm.companyForm.name),callback:function ($$v) {_vm.$set(_vm.companyForm, "name", $$v)},expression:"companyForm.name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Company type","rules":"required","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"input",attrs:{"type":"text","id":"type","error-messages":errors,"items":_vm.items,"label":"Company Type","required":""},model:{value:(_vm.companyForm.type),callback:function ($$v) {_vm.$set(_vm.companyForm, "type", $$v)},expression:"companyForm.type"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Company Email","mode":"eager","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"type":"text","error-messages":errors,"label":"Company Email","required":""},model:{value:(_vm.companyForm.email),callback:function ($$v) {_vm.$set(_vm.companyForm, "email", $$v)},expression:"companyForm.email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Business registration number","mode":"eager","rules":"min:6|alpha_num"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"type":"text","error-messages":errors,"label":"Business Registration Number","required":""},model:{value:(_vm.companyForm.business_regno),callback:function ($$v) {_vm.$set(_vm.companyForm, "business_regno", $$v)},expression:"companyForm.business_regno"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Business KRA Pin","mode":"eager","rules":"min:11|max:11|alpha_num"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"type":"text","error-messages":errors,"label":"Business KRA Pin","required":""},model:{value:(_vm.companyForm.kra_pin),callback:function ($$v) {_vm.$set(_vm.companyForm, "kra_pin", $$v)},expression:"companyForm.kra_pin"}})]}}],null,true)}),_c('vue-tel-input',{staticClass:"none",attrs:{"defaultCountry":"KE","inputOptions":{
            placeholder: "Director's number"
          },"required":""},on:{"validate":_vm.extractPhoneNumber},model:{value:(_vm.companyForm.display_number),callback:function ($$v) {_vm.$set(_vm.companyForm, "display_number", $$v)},expression:"companyForm.display_number"}}),_c('ValidationProvider',{attrs:{"name":"Company address","rules":"required|min:4","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"type":"text","error-messages":errors,"label":"Company Address","required":""},model:{value:(_vm.companyForm.address),callback:function ($$v) {_vm.$set(_vm.companyForm, "address", $$v)},expression:"companyForm.address"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Physical location","rules":"required|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"type":"text","error-messages":errors,"label":"Physical Location","required":""},model:{value:(_vm.companyForm.physical_location),callback:function ($$v) {_vm.$set(_vm.companyForm, "physical_location", $$v)},expression:"companyForm.physical_location"}})]}}],null,true)}),_c('div',{staticClass:"mb-2 midnightGreen--text text-body-1 text-decoration-underline"},[_c('router-link',{staticClass:"router-link",attrs:{"to":"/why-business-info"}},[_vm._v("Why do we need this information?")])],1)],1)],1),_c('div',[_c('CompanyCreationNotice',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var attrs = ref.attrs;
          var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"midnightGreen--text py-10 text-h6 text-capitalize font-weight-bold rounded-corner",attrs:{"tile":"","width":_vm.width,"large":"","color":"#F8B234","disabled":_vm.isInvalid(invalid, _vm.validNumber)}},'v-btn',attrs,false),on),[_vm._v(" Submit ")])]}}],null,true)})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }