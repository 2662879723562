export const registration = "registration";
export const verifyEmail = "verifyEmail";
export const login = "login";
export const logout = "logout";
export const forgotPassword = "forgotPassword";
export const resetPassword = "resetPassword";
export const fetchTokenFromUrl = "fetchTokenFromUrl";
export const changePassword = "changePassword";

export const fetchProfile = "fetchProfile";
export const updateProfile = "updateProfile";
export const deleteProfile = "deleteProfile";

export const createCompany = "createCompany";
export const updateCompany = "updateCompany";
export const deleteCompany = "deleteCompany";
export const fetchCompanies = "fetchCompanies";
export const fetchMyCompanies = "fetchMyCompanies";
export const fetchCompanyById = "fetchCompanyById";

export const addProduct = "addProduct";
export const updateProduct = "updateProduct";
export const deleteProduct = "deleteProduct";
export const fetchProducts = "fetchProducts";
export const fetchProductById = "fetchProductById";
export const fetchProductImagesById = "fetchProductImagesById";
export const fetchProductsByCompany = "fetchProductsByCompany";

export const addPricing = "addPricing";
export const updatePricing = "updatePricing";
export const deletePricing = "deletePricing";
export const fetchPricingById = "fetchPricingById";
export const fetchPricingByProductId = "fetchPricingByProductId";

export const expressInterest = "expressInterest";
export const acceptInterest = "acceptInterest";
export const declineInterest = "declineInterest";
export const fetchInterests = "fetchInterests";
export const fetchInterestsByProductId = "fetchInterestsByProductId";

export const onFileSelected = "onFileSelected";
export const uploadFiles = "uploadFiles";

export const formatTrainingData = "formatTrainingData";
export const filterByTag = "filterByTag";
export const fetchTags = "fetchTags";
export const filterByPreferences = "filterByPreferences";
export const fetchTrainingContent = "fetchTrainingContent";
export const fetchTrainingContentById = "fetchTrainingContentById";
export const selectedCourses = "selectedCourses";

export const fetchNotifications = "fetchNotifications";
export const notificationRead = "notificationRead";

export const fireAlert = "fireAlert";
export const confirmationAlert = "confirmationAlert";
export const error = "error";

export const searchProducts = "searchProducts";
export const searchCompanies = "searchCompanies";

export const redirect = "redirect";
