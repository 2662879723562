<template>
  <div class="background">
    <slot />
  </div>
</template>

<script>
export default {
  name: "BackgroundFixed"
};
</script>

<style scoped>
.background {
  height: 100vh;
  width: 100vw;
  background: white;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
