<template>
  <BottomCard icon="error" iconColor="pink">
    <template v-slot:button="{ on, attrs }">
      <slot :attrs="attrs" :on="on" />
    </template>
    <template v-slot:content>
      <v-row>
        <v-col cols="12">
          <v-card-title class="text-h5 midnightGreen--text font-weight-bold"
            >Important Information</v-card-title
          >
          <v-card-text
            v-if="!companyForm.business_regno || !companyForm.kra_pin"
            class="midnightGreen--text text-subtitle-1"
          >
            Your business registration number and KRA PIN are required for
            verification to buy and sell on Jukua. Do you still want to continue
            registering without them?
          </v-card-text>
          <v-card-text v-else class="midnightGreen--text text-subtitle-1">
            Your business registration number and KRA PIN will be used for
            verification purposes.
          </v-card-text>
        </v-col>
      </v-row>
      <v-btn
        :class="
          `font-weight-bold py-8 text-h6 text-capitalize midnightGreen--text`
        "
        large
        width="100%"
        color="primary"
        @click="createCompany()"
        >Continue</v-btn
      >
    </template>
  </BottomCard>
</template>

<script>
import { mapActions, mapState } from "vuex";

import BottomCard from "@/components/BottomCard";

export default {
  name: "CompanyCreationNotice",
  components: {
    BottomCard
  },
  computed: {
    ...mapState(["companyForm"])
  },
  methods: mapActions(["createCompany"])
};
</script>
