<template>
  <Card displayControls closeable title="Edit Company">
    <ValidationObserver
      class="flex d-flex flex-column justify-space-between"
      ref="obs"
      v-slot="{ invalid, changed }"
    >
      <v-card flat class="px-8 pt-5">
        <ImageInput />
        <v-form v-if="company">
          <ValidationProvider name="Company" rules="min:3" v-slot="{ errors }">
            <v-text-field
              v-model="formItems.name"
              :error-messages="errors"
              label="Company Name"
              :placeholder="company.name"
              required
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            name="Company type"
            rules="min:3"
            v-slot="{ errors }"
          >
            <v-select
              v-model="formItems.type"
              :error-messages="errors"
              :items="items"
              label="Company type"
              :placeholder="company.type"
              required
            ></v-select>
          </ValidationProvider>
          <ValidationProvider
            name="Company Email"
            rules="email"
            v-slot="{ errors }"
          >
            <v-text-field
              class="mb-1"
              v-model="formItems.email"
              :error-messages="errors"
              label="Company Email"
              :placeholder="company.email"
              required
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            name="Business registration number"
            mode="eager"
            rules="min:6|alpha_num"
            v-slot="{ errors }"
          >
            <v-text-field
              class="input"
              type="text"
              v-model="formItems.business_regno"
              :error-messages="errors"
              label="Business Registration Number"
              :placeholder="company.business_regno"
              required
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            name="Business KRA Pin"
            rules="min:11|max:11|alpha_num"
            v-slot="{ errors }"
          >
            <v-text-field
              class="mb-1"
              v-model="formItems.kra_pin"
              :error-messages="errors"
              label="Business KRA Pin"
              :placeholder="company.kra_pin"
              required
            ></v-text-field>
          </ValidationProvider>
          <vue-tel-input
            v-model="phoneNumber"
            @validate="extractPhoneNumber"
            defaultCountry="KE"
            :inputOptions="{
              placeholder: `Director's number: ${company.phone_number}`
            }"
            class="none"
          ></vue-tel-input>
          <ValidationProvider
            name="Company address"
            rules="min:4"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="formItems.address"
              :error-messages="errors"
              label="Company address"
              :placeholder="company.address"
              required
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            name="Company location"
            rules="min:4"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="formItems.physical_location"
              :error-messages="errors"
              label="Company location"
              :placeholder="company.physical_location"
              required
            ></v-text-field>
          </ValidationProvider>
        </v-form>
        <v-card flat class="d-flex align-items-center py-2">
          <v-icon color="#35A19E">delete</v-icon>
          <span
            @click="
              confirmationAlert({
                companyId: $route.params.id,
                propertyToDelete: 'company'
              })
            "
            class="midnightGreen--text delete--button text-decoration-underline text-capitalize text-h6 font-weight-normal"
            >Delete Company</span
          >
        </v-card>
      </v-card>
      <BaseButton
        @click.native="submit"
        :invalid="isValidForm(invalid, changed, validNumber)"
        :loading="buttonLoading"
        >Save</BaseButton
      >
    </ValidationObserver>
  </Card>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

import Card from "@/components/Card";
import BaseButton from "@/components/BaseButton";
import ImageInput from "@/components/ImageInput";
import { filterObjectMixin } from "@/mixins/filterObjectMixin";

const initialState = () => ({
  formItems: {
    name: "",
    type: "",
    phone_number: "",
    address: "",
    physical_location: "",
    email: "",
    kra_pin: "",
    business_regno: ""
  },
  phoneNumber: "",
  items: ["Limited Company", "Limited Liability Partnership", "Business"],
  validNumber: false
});

export default {
  name: "EditCompany",
  mixins: [filterObjectMixin],
  components: {
    Card,
    BaseButton,
    ImageInput
  },
  data: () => initialState(),
  async created() {
    await this.setIsLoading();
    await this.fetchCompanyById(this.$route.params.id);
    await this.setLoaded();
  },
  computed: {
    ...mapState(["company", "previewImage", "buttonLoading"]),
    ...mapGetters(["getPreviewImage"])
  },
  methods: {
    ...mapActions([
      "fetchCompanyById",
      "uploadFiles",
      "updateCompany",
      "confirmationAlert"
    ]),
    ...mapMutations([
      "setIsLoading",
      "setLoaded",
      "setButtonLoading",
      "setButtonLoaded"
    ]),
    async submit() {
      this.setButtonLoading();

      if (this.previewImage) {
        await this.uploadFiles({
          id: this.$route.params.id,
          category: "company"
        });
      }

      const filteredValues = this.filterEmptyFormValues(this.formItems);

      if (this.objectIsFilled(filteredValues)) {
        await this.updateCompany({
          id: this.$route.params.id,
          formItems: filteredValues
        });

        this.resetData();
      }

      this.setButtonLoaded();
    },
    extractPhoneNumber(phoneObject) {
      this.formItems.phone_number = phoneObject.number;
      this.validNumber = phoneObject.valid ?? false;
    },
    isValidForm(invalid, changed, validNumber) {
      if (validNumber === true && invalid === false) {
        return false;
      }

      if (!invalid && changed) {
        return false;
      }

      if (this.previewImage) {
        return false;
      }

      return true;
    },
    resetData() {
      Object.assign(this.$data, initialState());
    }
  }
};
</script>

<style scoped>
.none {
  border: none;
  border-bottom: 1px solid #888;
  margin-bottom: 1.2rem;
}
</style>
