<template>
  <Card
    displayControls
    pathTo
    :location="() => $router.push('/dashboard')"
    title="My Profile"
    to="/profile/edit"
    editable
    editMessage="Edit profile"
  >
    <div class="px-5 pt-5 d-flex flex-row align-items-center">
      <v-avatar class="mr-3 mb-2" :size="width" color="grey lighten-2">
        <v-img v-if="profile.url" :src="profile.url"></v-img>
        <v-icon v-else class="v-icon" size="90">mdi-account</v-icon>
      </v-avatar>
      <v-col>
        <h2 class="midnightGreen--text">
          Hey, {{ capitalizeEachName(profile.name) }}
        </h2>
        <p class="text-muted">{{ profile.email }}</p>
      </v-col>
    </div>
    <v-divider inset></v-divider>
    <v-list class="flex" v-if="!myCompanies.length">
      <v-list-item-title
        class="text-h5 font-weight-bold pl-6 pb-3 midnightGreen--text"
        >Your Companies</v-list-item-title
      >
      <v-card flat class="d-flex flex-column">
        <v-img src="@/assets/noProducts.png" class="mx-auto" width="50%" />
        <v-card flat class="text-center">
          <p class="h6 midnightGreen--text">
            You have no companies yet<br />Add a company to get started
          </p>
        </v-card>
      </v-card>
    </v-list>
    <v-list class="pa-0 flex" v-else>
      <h2 class="text-h5 font-weight-bold pl-6 midnightGreen--text">
        My Companies
      </h2>
      <v-list-item-group
        class="px-5 pt-3"
        v-for="company in myCompanies"
        :key="company.id"
      >
        <v-list-item
          router
          :to="{ name: 'Company', params: { id: company.id } }"
          class="py-2"
        >
          <v-list-item-avatar
            tile
            size="100"
            color="grey lighten-4"
            class="rounded-tr-xl elevation-1 mr-4"
          >
            <v-img v-if="company.url" :src="company.url"></v-img>
            <v-icon v-else color="midnightGreen" size="80"
              >account_balance</v-icon
            >
          </v-list-item-avatar>
          <v-list-item-content three-line>
            <v-list-item-title
              class="text-h6 font-weight-normal midnightGreen--text"
              >{{ company.name }}
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    v-if="company.status === 'verified'"
                    size="20"
                    color="green"
                    tile
                  >
                    mdi-shield-check
                  </v-icon>
                </template>
                <span>Business verified</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    v-if="company.status === 'unverified'"
                    size="20"
                    color="red"
                    tile
                  >
                    mdi-shield-alert
                  </v-icon>
                </template>
                <span>Business not verified</span>
              </v-tooltip>
            </v-list-item-title>
            <br />
            <p class="small font-weight-bold pt-1">{{ company.type }}</p>
            <v-list-item-subtitle>
              <v-chip
                small
                color="secondaryNeutral"
                text-color="midnightGreen"
                label
                class="text-uppercase mt-1"
                >{{ company.physical_location }}</v-chip
              >
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider inset></v-divider>
      </v-list-item-group>
    </v-list>
    <BaseButton
      to="/register-onboarding"
      class="d-flex align-bottom justify-self-end"
      >Add Company</BaseButton
    >
  </Card>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

import Card from "@/components/Card";
import BaseButton from "@/components/BaseButton";
import { widthMixin } from "@/mixins/widthMixin";

export default {
  name: "MyProfile",
  mixins: [widthMixin],
  components: {
    Card,
    BaseButton
  },
  async created() {
    await this.setIsLoading();
    await this.fetchProfile();
    await this.fetchMyCompanies();
    await this.setLoaded();
  },
  data() {
    return {
      smallScreen: "100",
      largeScreen: "130"
    };
  },
  computed: {
    ...mapState(["profile", "myCompanies"])
  },
  methods: {
    ...mapActions(["fetchProfile", "fetchMyCompanies"]),
    ...mapMutations(["setIsLoading", "setLoaded"]),
    capitalizeEachName(names) {
      return names
        ? names
            .split(" ")
            .map(name => name[0].toUpperCase() + name.substring(1))
            .join(" ")
        : null;
    }
  }
};
</script>
