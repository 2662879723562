<template>
  <Card
    displayControls
    pathTo
    :location="redirectToProfileOrDashboard"
    :title="outputCompanyName | capitalize"
    :editable="mine"
    editMessage="Edit company"
    :to="`/company/${$route.params.id}/edit`"
  >
    <div class="px-5 pt-5 d-flex flex-row align-items-center">
      <v-avatar class="mr-3 mb-1" :size="width" color="grey lighten-2">
        <v-img v-if="company && company.url" :src="company.url" />
        <v-icon v-else class="v-icon" size="90">mdi-account</v-icon>
      </v-avatar>
      <v-col v-if="company" class="pa-0">
        <div class="d-flex">
          <h3 class="font-weight-bold midnightGreen--text pr-2">
            {{ company.name | capitalize }}
          </h3>
          <v-tooltip v-if="company.status === 'verified'" top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" size="20" color="green" tile>
                mdi-shield-check
              </v-icon>
            </template>
            <span>Business verified</span>
          </v-tooltip>
          <v-tooltip v-if="company.status === 'unverified'" top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" size="20" color="red" tile>
                mdi-shield-alert
              </v-icon>
            </template>
            <span>Business not verified</span>
          </v-tooltip>
        </div>
        <p class="text-muted">{{ company.type }}</p>
        <v-chip
          small
          class="text-uppercase"
          color="secondaryNeutral"
          text-color="midnightGreen"
          label
          >{{ company.physical_location }}</v-chip
        >
      </v-col>
    </div>
    <v-divider inset></v-divider>
    <v-card flat class="px-5 flex">
      <h4 class="font-weight-bold midnightGreen--text">
        {{ mine ? "My Products" : "Products" }}
      </h4>
      <v-row class="mx-4 justify-center" v-if="!companyProducts.length">
        <v-col cols="auto" class="text-center">
          <v-img src="@/assets/noProducts.png" />
          <p class="text-h6 midnightGreen--text font-weight-bold">
            No products uploaded yet
          </p>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col
          lg="6"
          md="6"
          sm="12"
          cols="12"
          v-for="product in companyProducts"
          :key="product.id"
        >
          <router-link
            :to="{
              name: 'Product',
              params: {
                id: product.id,
                redirectToRoute: $route.fullPath
              },
              query: {
                company: $route.params.id
              }
            }"
          >
            <v-list-item-content>
              <v-list-item-avatar
                tile
                size="200"
                class="rounded-tr-xl elevation-1"
              >
                <v-img v-if="product.url" :src="product.url"></v-img>
                <v-img v-else src="@/assets/company-placeholder.svg"></v-img>
              </v-list-item-avatar>
              <v-list-item-title class="text-h6 font-weight-normal">{{
                product.name
              }}</v-list-item-title>
              <v-list-item-title class="grey--text"
                >{{ product.min_price }} KES to {{ product.max_price }} KES per
                {{ product.unit | lowercase }}</v-list-item-title
              >
            </v-list-item-content>
          </router-link>
        </v-col>
      </v-row>
    </v-card>
    <BaseButton v-if="mine" :to="`/products/${$route.params.id}`"
      >Upload Products</BaseButton
    >
  </Card>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

import Card from "@/components/Card";
import BaseButton from "@/components/BaseButton";
import { widthMixin } from "@/mixins/widthMixin";
import { checkRedirectRouteMixin } from "@/mixins/checkRedirectRouteMixin";
import { clearLastRouteMixin } from "@/mixins/clearLastRouteMixin";

export default {
  name: "Company",
  mixins: [widthMixin, checkRedirectRouteMixin, clearLastRouteMixin],
  props: {
    redirectToRoute: String
  },
  components: {
    Card,
    BaseButton
  },
  data() {
    return {
      smallScreen: "100",
      largeScreen: "130",
      company_name: "",
      company_type: "",
      company_status: "",
      location: "",
      mine: true
    };
  },
  async created() {
    await this.setIsLoading();
    await this.fetchCompanyById(this.$route.params.id);
    await this.fetchProductsByCompany(this.$route.params.id);
    this.checkCompanyOwnership();
    await this.setLoaded();
    this.checkRedirectRoute();
  },
  computed: {
    ...mapState(["company", "companyProducts", "redirectRoute"]),
    outputCompanyName() {
      return this.company?.name ?? "";
    }
  },
  methods: {
    ...mapActions(["fetchCompanyById", "fetchProductsByCompany"]),
    ...mapMutations([
      "setIsLoading",
      "setLoaded",
      "setRedirectRoute",
      "filterRedirectRoute"
    ]),
    checkCompanyOwnership() {
      if (this.company?.company_owner === "Y") {
        this.mine = true;
        return;
      }

      this.mine = false;
    },
    redirectToProfileOrDashboard() {
      if (Object.keys(this.redirectRoute).length) {
        return this.goBackAndClearLastRoute();
      }

      return this.$router.push("/profile");
    }
  }
};
</script>
