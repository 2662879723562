<template>
  <Card
    displayControls
    notifications
    title="Add Pricing"
    pathTo
    :location="goBackAndClearLastRoute"
  >
    <ValidationObserver
      class="d-flex flex flex-column justify-space-between"
      ref="observer"
      v-slot="{ invalid }"
    >
      <v-card class="px-10 pt-7 flex" flat>
        <h1 class="midnightGreen--text">
          Pricing
          <br />Product
        </h1>

        <v-form>
          <v-row>
            <v-col cols="5">
              <ValidationProvider
                name="Price"
                rules="required|numeric"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model.number="min_price"
                  class="input"
                  type="text"
                  label="Min Price in KES"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="2">
              <p class="mt-4 font-weight-medium text-center to">
                to
              </p>
            </v-col>
            <v-col cols="5">
              <ValidationProvider
                name="Price"
                rules="required|numeric"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model.number="max_price"
                  class="input"
                  type="text"
                  label="Max Price in KES"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
          <ValidationProvider
            name="Quantity"
            :rules="`required|numeric|max_value:${maxQuantity}`"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model.number="min_unit"
              :label="
                `Minimum Order Quantity ${retrieveCurrentMaxQuantityAvailable}`
              "
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            name="Cost of Delivery"
            rules="required|min:3"
            v-slot="{ errors }"
          >
            <v-select
              v-model="delivery_cost"
              label="Cost of Delivery"
              dense
              :items="deliveryCosts"
              :error-messages="errors"
              class="input"
            ></v-select>
          </ValidationProvider>
        </v-form>
      </v-card>
      <BaseButton
        buttonColor="blueGreen"
        textColor="white"
        :invalid="invalid"
        :loading="buttonLoading"
        @click.native="
          addPricing({
            productId: $route.params.productId,
            companyId: $route.params.companyId,
            currentRoute: $route.fullPath,
            data: { min_price, max_price, min_unit, delivery_cost }
          })
        "
        >Next</BaseButton
      >
    </ValidationObserver>
  </Card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

import Card from "@/components/Card";
import BaseButton from "@/components/BaseButton";
import { clearLastRouteMixin } from "@/mixins/clearLastRouteMixin";
import { checkRedirectRouteMixin } from "@/mixins/checkRedirectRouteMixin";

export default {
  name: "ProductPricing",
  mixins: [clearLastRouteMixin, checkRedirectRouteMixin],
  props: {
    redirectToRoute: String
  },
  components: {
    Card,
    BaseButton
  },
  data() {
    return {
      min_price: "",
      max_price: "",
      min_unit: "",
      delivery_cost: "",
      deliveryCosts: [
        "Free in all areas",
        "Free in some areas",
        "User pays for delivery"
      ]
    };
  },
  async created() {
    await this.setIsLoading();
    await this.fetchProductById(this.$route.params.productId);
    await this.setLoaded();
    this.checkRedirectRoute();
  },
  computed: {
    ...mapState(["redirectRoute", "product", "buttonLoading"]),
    maxQuantity() {
      if (this.product.quantity) {
        return this.product.quantity;
      }

      return Number.MAX_VALUE;
    },
    retrieveCurrentMaxQuantityAvailable() {
      if (this.product.quantity && this.product.unit) {
        return `(Available Quantity: ${this.product.quantity} ${this.product.unit})`;
      }

      return "";
    }
  },
  methods: {
    ...mapActions(["fetchProductById", "addPricing"]),
    ...mapMutations([
      "setIsLoading",
      "setLoaded",
      "setRedirectRoute",
      "filterRedirectRoute"
    ])
  }
};
</script>
