<template>
  <Card displayControls :title="product.company_name | capitalize">
    <v-card
      flat
      class="d-flex flex flex-column justify-space-between"
      v-if="isInterested"
    >
      <div class="pa-16">
        <SuccessIcon width="100">
          A notification of your interest has been sent to
          {{ product.company_name | capitalize }}
        </SuccessIcon>
      </div>
      <BaseButton buttonColor="blueGreen" textColor="white" to="/dashboard"
        >Continue Shopping</BaseButton
      >
    </v-card>
    <v-card
      class="pa-0"
      flat
      v-if="product.company_owner === 'N' && !isInterested"
    >
      <v-card flat class="pa-0">
        <v-card tile flat class="rounded-tr-xl pa-5">
          <v-img
            v-if="product.url"
            :src="product.url"
            class="elevation-1"
            height="20vh"
          />
          <v-img v-else height="30vh" src="@/assets/no-company-logo.jpg" />
        </v-card>
        <v-row class="mx-1">
          <v-col cols="12"> </v-col>
          <v-col cols="8">
            <v-card-title class="darkBlue--text px-0 text-h5 text-capitalize">{{
              product.name
            }}</v-card-title>
            <v-chip
              small
              color="blueGreen"
              text-color="white"
              class="text-subtitle-1"
              label
              >{{ product.location }}</v-chip
            >
          </v-col>
        </v-row>
      </v-card>
      <v-divider />
      <v-card v-if="pricings.length" flat class="mx-5">
        <v-card-title class="text-h6 darkBlue--text font-weight-bold"
          >Select Price(s)</v-card-title
        >
        <v-list three-line nav>
          <v-radio-group v-model="pricingId">
            <v-list-item-group v-for="price in pricings" :key="price.id">
              <v-list-item :ripple="false" inactive>
                <v-radio :value="price.id" class="pr-3" />
                <v-list-item-content>
                  <v-list-item-title
                    class="text-h6 font-weight-normal blueGreen--text"
                    >Price per unit: {{ price.min_price }} KES -
                    {{ price.max_price }} KES
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-subtitle-1">
                    per
                    {{ product.unit | lowercase }} &#9679; Min. Order
                    {{ price.min_unit }} {{ product.unit | lowercase }}
                  </v-list-item-subtitle>
                  <v-list-item-title class="text-subtitle-1">
                    {{ price.delivery_cost }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-divider inset></v-divider>
            </v-list-item-group>
          </v-radio-group>
        </v-list>
        <v-card class="d-flex flex mb-5">
          <v-btn
            v-if="company"
            :disabled="pricingId && !companiesAvailable"
            class="flex py-8 text-h6 text-capitalize midnightGreen--text font-weight-bold"
            @click="expressInterest({ pricingId, companyId })"
            depressed
            small
            color="primary"
            >Express Interest</v-btn
          >
          <ExpressInterestScrollable
            v-else
            :pricingId="pricingId"
            :companiesAvailable="companiesAvailable"
            :companiesForExpression="companiesForExpression"
          />
        </v-card>
      </v-card>
    </v-card>
  </Card>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";

import Card from "@/components/Card";
import SuccessIcon from "@/components/SuccessIcon";
import BaseButton from "@/components/BaseButton";
import ExpressInterestScrollable from "@/components/ExpressInterestScrollable";

export default {
  name: "Interest",
  components: {
    BaseButton,
    Card,
    SuccessIcon,
    ExpressInterestScrollable
  },
  data() {
    return {
      pricingId: "",
      companyId: "",
      company: null,
      companiesAvailable: false,
      companiesForExpression: []
    };
  },
  async created() {
    await this.setIsLoading();
    await this.fetchMyCompanies();
    this.checkCompaniesAvailable();
    await this.fetchInterestsByProductId(this.$route.query.product);
    await this.fetchProductById(this.$route.query.product);
    await this.fetchPricingByProductId(this.$route.query.product);
    this.extractCompanyIds();
    await this.expressedInterest();
    await this.setLoaded();
  },
  computed: {
    ...mapGetters(["isLoading"]),
    ...mapState([
      "product",
      "pricings",
      "myCompanies",
      "isInterested",
      "productInterests"
    ])
  },
  methods: {
    ...mapMutations(["setValueOfIsInterested", "setIsLoading", "setLoaded"]),
    ...mapActions([
      "expressInterest",
      "fetchPricingByProductId",
      "fetchProductById",
      "fetchInterestsByProductId",
      "fetchMyCompanies"
    ]),
    extractCompanyIds() {
      if (this.companiesAvailable) {
        const verifiedCompanies = this.checkCompaniesBasedOnStatus("verified");
        const unverifiedCompanies = this.checkCompaniesBasedOnStatus(
          "unverified"
        );

        const companies = [...verifiedCompanies, ...unverifiedCompanies];

        this.checkIfSingleCompanyPresent(companies);
      }
    },
    checkIfSingleCompanyPresent(companies) {
      if (companies.length === 1) {
        const [company] = companies;
        this.company = company;
        this.companyId = company.id;
        this.companiesForExpression = [];
        return;
      }

      this.companiesForExpression = companies;
    },
    checkCompaniesAvailable() {
      this.companiesAvailable = !!this.myCompanies.length;
      return !!this.myCompanies.length;
    },
    checkCompaniesBasedOnStatus(status) {
      return this.myCompanies
        .filter(company => company.status === status)
        .map(company => ({
          id: company.id,
          name: company.name,
          physical_location: company.physical_location,
          email: company.email
        }));
    },
    async expressedInterest() {
      if (this.product.company_owner === "N") {
        const result = this.productInterests.filter(interest => {
          return (
            parseInt(this.$route.query.product) === interest.product_id &&
            interest.status === "notified"
          );
        });

        await this.setValueOfIsInterested(!!result.length);
      }
    }
  }
};
</script>
