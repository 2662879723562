<template class="register-page">
  <Card displayControls title="Company Registration">
    <ValidationObserver
      ref="obs"
      v-slot="{ invalid }"
      class="main-container d-flex flex-column justify-content-between"
    >
      <div class="px-7 pt-7">
        <h1 class="midnightGreen--text">
          Company
          <br />Info
        </h1>
        <v-form class="offset-x">
          <ValidationProvider
            name="Company name"
            mode="eager"
            rules="required|min:3"
            v-slot="{ errors }"
          >
            <v-text-field
              class="input"
              type="text"
              id="name"
              v-model="companyForm.name"
              :error-messages="errors"
              label="Company Name"
              required
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            name="Company type"
            rules="required"
            mode="eager"
            v-slot="{ errors }"
          >
            <v-select
              class="input"
              type="text"
              id="type"
              v-model="companyForm.type"
              :error-messages="errors"
              :items="items"
              label="Company Type"
              required
            ></v-select>
          </ValidationProvider>
          <ValidationProvider
            name="Company Email"
            mode="eager"
            rules="required|email"
            v-slot="{ errors }"
          >
            <v-text-field
              class="input"
              type="text"
              v-model="companyForm.email"
              :error-messages="errors"
              label="Company Email"
              required
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            name="Business registration number"
            mode="eager"
            rules="min:6|alpha_num"
            v-slot="{ errors }"
          >
            <v-text-field
              class="input"
              type="text"
              v-model="companyForm.business_regno"
              :error-messages="errors"
              label="Business Registration Number"
              required
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            name="Business KRA Pin"
            mode="eager"
            rules="min:11|max:11|alpha_num"
            v-slot="{ errors }"
          >
            <v-text-field
              class="input"
              type="text"
              v-model="companyForm.kra_pin"
              :error-messages="errors"
              label="Business KRA Pin"
              required
            ></v-text-field>
          </ValidationProvider>
          <vue-tel-input
            v-model="companyForm.display_number"
            @validate="extractPhoneNumber"
            defaultCountry="KE"
            :inputOptions="{
              placeholder: `Director's number`
            }"
            required
            class="none"
          ></vue-tel-input>
          <ValidationProvider
            name="Company address"
            rules="required|min:4"
            mode="eager"
            v-slot="{ errors }"
          >
            <v-text-field
              class="input"
              type="text"
              v-model="companyForm.address"
              :error-messages="errors"
              label="Company Address"
              required
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            name="Physical location"
            rules="required|min:4"
            v-slot="{ errors }"
          >
            <v-text-field
              class="input"
              type="text"
              v-model="companyForm.physical_location"
              :error-messages="errors"
              label="Physical Location"
              required
            ></v-text-field>
          </ValidationProvider>
          <div
            class="mb-2 midnightGreen--text text-body-1 text-decoration-underline"
          >
            <router-link class="router-link" to="/why-business-info"
              >Why do we need this information?</router-link
            >
          </div>
        </v-form>
      </div>
      <div>
        <CompanyCreationNotice v-slot="{ attrs, on }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            tile
            class="midnightGreen--text py-10 text-h6 text-capitalize font-weight-bold rounded-corner"
            :width="width"
            large
            color="#F8B234"
            :disabled="isInvalid(invalid, validNumber)"
          >
            Submit
          </v-btn>
        </CompanyCreationNotice>
      </div>
    </ValidationObserver>
  </Card>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import Card from "@/components/Card";
import CompanyCreationNotice from "@/components/CompanyCreationNotice";
import { widthMixin } from "@/mixins/widthMixin";

export default {
  name: "CompanyRegistration",
  mixins: [widthMixin],
  components: {
    Card,
    CompanyCreationNotice
  },
  data() {
    return {
      items: ["Limited Company", "Limited Liability Partnership", "Business"],
      smallScreen: "100%",
      largeScreen: "35%",
      validNumber: false
    };
  },
  computed: {
    ...mapState(["companyForm"]),
    ...mapGetters(["isLoading", "isNext"])
  },
  methods: {
    extractPhoneNumber(phoneObject) {
      this.companyForm.phone_number = phoneObject.number;
      this.validNumber = phoneObject.valid ?? false;
    },
    isInvalid(invalid, validNumber) {
      if (validNumber === true && invalid === false) {
        return false;
      }

      return true;
    }
  }
};
</script>

<style scoped>
.main-container {
  height: 100%;
}

.rounded-corner {
  border-top-right-radius: 30px;
}

.none {
  border: none;
  border-bottom: 1px solid #888;
  margin-bottom: 1.2rem;
}
</style>
