var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Card',{attrs:{"displayControls":"","notifications":"","title":"Add Pricing","pathTo":"","location":_vm.goBackAndClearLastRoute}},[_c('ValidationObserver',{ref:"observer",staticClass:"d-flex flex flex-column justify-space-between",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',{staticClass:"px-10 pt-7 flex",attrs:{"flat":""}},[_c('h1',{staticClass:"midnightGreen--text"},[_vm._v(" Pricing "),_c('br'),_vm._v("Product ")]),_c('v-form',[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('ValidationProvider',{attrs:{"name":"Price","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"type":"text","label":"Min Price in KES","error-messages":errors},model:{value:(_vm.min_price),callback:function ($$v) {_vm.min_price=_vm._n($$v)},expression:"min_price"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('p',{staticClass:"mt-4 font-weight-medium text-center to"},[_vm._v(" to ")])]),_c('v-col',{attrs:{"cols":"5"}},[_c('ValidationProvider',{attrs:{"name":"Price","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"type":"text","label":"Max Price in KES","error-messages":errors},model:{value:(_vm.max_price),callback:function ($$v) {_vm.max_price=_vm._n($$v)},expression:"max_price"}})]}}],null,true)})],1)],1),_c('ValidationProvider',{attrs:{"name":"Quantity","rules":("required|numeric|max_value:" + _vm.maxQuantity)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":("Minimum Order Quantity " + _vm.retrieveCurrentMaxQuantityAvailable),"error-messages":errors},model:{value:(_vm.min_unit),callback:function ($$v) {_vm.min_unit=_vm._n($$v)},expression:"min_unit"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Cost of Delivery","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"input",attrs:{"label":"Cost of Delivery","dense":"","items":_vm.deliveryCosts,"error-messages":errors},model:{value:(_vm.delivery_cost),callback:function ($$v) {_vm.delivery_cost=$$v},expression:"delivery_cost"}})]}}],null,true)})],1)],1),_c('BaseButton',{attrs:{"buttonColor":"blueGreen","textColor":"white","invalid":invalid,"loading":_vm.buttonLoading},nativeOn:{"click":function($event){return _vm.addPricing({
          productId: _vm.$route.params.productId,
          companyId: _vm.$route.params.companyId,
          currentRoute: _vm.$route.fullPath,
          data: { min_price: _vm.min_price, max_price: _vm.max_price, min_unit: _vm.min_unit, delivery_cost: _vm.delivery_cost }
        })}}},[_vm._v("Next")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }