export const filterObjectMixin = {
  methods: {
    filterEmptyFormValues(formData) {
      const formObject = this.assertFormIsAnObject(formData);
      const filterEmptyFormValues = Object.entries(formObject).filter(form => {
        return !!form[1];
      });

      if (Object.entries(filterEmptyFormValues).length) {
        const filteredObjected = Object.fromEntries(filterEmptyFormValues);

        return filteredObjected;
      }
    },
    assertFormIsAnObject(form) {
      if (typeof form === "object" && form) return form;

      return false;
    },
    objectIsFilled(form) {
      const formObject = this.assertFormIsAnObject(form);
      return !!Object.keys(formObject).length;
    }
  }
};
