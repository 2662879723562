import { DateTime, Interval } from "luxon";

/**
 *
 * @usageNotes pass in a serverTimestamp string using UTC time format yyyy-MM-dd'T'HH:mm:ssssZ
 * @param serverTimestamp string in format yyyy-mm-dd
 * @returns string denoting duration since param
 */

export const timeElapsed = serverTimestamp => {
  const currentTime = DateTime.local();
  const previousTime = DateTime.fromISO(serverTimestamp);
  const interval = Interval.fromDateTimes(previousTime, currentTime);

  const { days, hours, minutes, seconds } = interval
    .toDuration(["days", "hours", "minutes", "seconds"])
    .toObject();

  if (days > 0) {
    return `${days} days ago`;
  }

  if (hours < 24 && hours > 0) {
    return `${hours}h ago`;
  }

  if (minutes > 0 && minutes < 60) {
    return `${minutes} mins ago`;
  }

  if (seconds < 60) {
    return `${Math.round(seconds)} seconds ago`;
  }
};
