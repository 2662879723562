<template>
  <div class="d-flex mb-3 image-container">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-avatar
          v-bind="attrs"
          v-on="on"
          color="grey lighten-2"
          :size="imageSize || 160"
        >
          <v-img v-if="getPreviewImage" :src="previewImage" />
          <v-icon v-else-if="image" color="midnightGreen" :size="iconSize || 80"
            >mdi-image</v-icon
          >
          <v-icon v-else color="midnightGreen" :size="iconSize || 80"
            >mdi-account</v-icon
          >
          <input
            ref="image"
            type="file"
            id="file"
            prepend-icon="mdi-camera"
            class="d-none"
            @input="onFileSelected"
          />
        </v-avatar>
      </template>
      <span>Please make sure the image is less than 2MB</span>
    </v-tooltip>
    <v-btn
      :style="`top: 6rem; left: 8rem; ${iconPosition}`"
      absolute
      small
      color="midnightGreen"
      id="updateImage"
      fab
      @click="selectImage"
    >
      <v-icon medium color="tertiary" v-if="getPreviewImage">mdi-pencil</v-icon>
      <v-icon medium color="tertiary" v-else>mdi-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "ImageInput",
  props: {
    image: Boolean,
    imageSize: String,
    iconSize: String,
    iconPosition: String
  },
  beforeDestroy() {
    this.setPreviewImage(null);
  },
  computed: {
    ...mapState(["previewImage"]),
    ...mapGetters(["getPreviewImage"])
  },
  methods: {
    ...mapActions(["onFileSelected"]),
    ...mapMutations(["setPreviewImage"]),
    selectImage() {
      this.$refs.image.click();
    }
  }
};
</script>

<style scoped>
.image-container {
  position: relative;
}

.avatar-btn {
  top: 6rem;
  left: 8rem;
}
</style>
