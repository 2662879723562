<template>
  <v-card
    :class="`d-flex ${controlsPlacement}`"
    flat
    tile
    :width="width"
    color="transparent"
  >
    <v-btn icon color="neutral" @click="pathTo ? location() : $router.back()">
      <v-icon v-if="closeable" large>mdi-close</v-icon>
      <v-icon v-else large>mdi-chevron-left</v-icon>
    </v-btn>
    <v-toolbar-title class="white--text text-h5 font-weight-bold pl-5">{{
      title
    }}</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn v-if="editable" color="neutral" icon :to="to">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" color="white">mdi-pencil</v-icon>
        </template>
        <span>{{ editMessage || "Edit" }}</span>
      </v-tooltip>
    </v-btn>
    <v-btn
      v-if="notifications && isLoggedIn"
      color="neutral"
      icon
      to="/notifications"
    >
      <v-icon v-if="notifications">mdi-bell</v-icon>
    </v-btn>
    <v-btn v-if="interests" color="neutral" icon to="/interests">
      <v-icon v-if="interests">mdi-information</v-icon>
    </v-btn>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import { widthMixin } from "@/mixins/widthMixin";

export default {
  name: "PageControls",
  mixins: [widthMixin],
  props: {
    to: String,
    title: String,
    editable: Boolean,
    closeable: Boolean,
    notifications: Boolean,
    interests: Boolean,
    editMessage: String,
    location: Function,
    pathTo: Boolean
  },
  data: () => ({
    smallScreen: "100%",
    largeScreen: "60%"
  }),
  computed: {
    ...mapGetters(["isLoggedIn"]),
    controlsPlacement() {
      switch (this.$vuetify.breakpoint.name) {
        default:
          return "control--height";
      }
    }
  }
};
</script>

<style scoped>
.control--height {
  position: absolute;
  bottom: 83%;
}
</style>
