import axios from "axios";

/**
 * Requires path as defined in ghost docs https://ghost.org/docs/api/v3/content/#endpoints and optional standard axios properties
 *
 * @requires path e.g. /posts
 */
export const ghostApi = axios.create({
  baseURL: process.env.VUE_APP_GHOST_URL
});

ghostApi.interceptors.request.use(
  config => {
    config.url = `${config.url}/?key=${process.env.VUE_APP_GHOST_KEY}`;

    if (config.page) {
      config.url = `${config.url}&page=${config.page}`;
    }

    return config;
  },
  error => Promise.reject(error)
);
