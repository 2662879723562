<template>
  <BackgroundFixed>
    <ValidationObserver v-slot="{ invalid }">
      <v-card flat class="card">
        <h1 class="blueGreen--text font-weight-bold px-5 py-10">
          Update your password
        </h1>
        <ValidationProvider
          name="Password"
          rules="required|min:5"
          v-slot="{ errors }"
        >
          <v-text-field
            class="input px-6 py-10"
            v-model="password"
            :error-messages="errors"
            label="Enter new password"
            type="password"
            required
            @keypress.enter="resetPassword(password)"
          ></v-text-field>
        </ValidationProvider>
        <v-btn
          color="blueGreen"
          large
          class="mx-5 font-weight-bold text-h6 white--text"
          :disabled="invalid"
          @click="resetPassword(password)"
          >Submit</v-btn
        >
      </v-card>
    </ValidationObserver>
  </BackgroundFixed>
</template>

<script>
import { mapActions } from "vuex";

import BackgroundFixed from "@/components/BackgroundFixed";

export default {
  name: "UpdatePassword",
  components: {
    BackgroundFixed
  },
  data() {
    return {
      password: ""
    };
  },
  mounted() {
    this.fetchTokenFromUrl();
  },

  methods: mapActions(["resetPassword", "fetchTokenFromUrl"])
};
</script>
