<template>
  <Product
    deletable
    hideImages
    disableInterestButton
    editable
    :to="toRoute"
    pathTo
    :location="
      () =>
        $router.push(
          `/product/${$route.params.id}?company=${$route.query.company}`
        )
    "
  >
    <v-card flat class="d-flex align-center pb-2 px-6">
      <v-icon color="#35A19E">delete</v-icon>
      <span
        @click="
          confirmationAlert({
            companyId: $route.query.company,
            productId: $route.params.id,
            propertyToDelete: 'product'
          })
        "
        class="midnightGreen--text delete--button text-decoration-underline text-capitalize text-h6 font-weight-normal"
        >Delete Product</span
      >
    </v-card>
    <BaseButton
      buttonColor="blueGreen"
      textColor="white"
      :to="toRoute"
      :loading="buttonLoading"
      >Edit Product</BaseButton
    >
  </Product>
</template>

<script>
import { mapActions, mapState } from "vuex";

import Product from "@/views/Product";
import BaseButton from "@/components/BaseButton";

export default {
  name: "EditProduct",
  components: {
    BaseButton,
    Product
  },
  computed: {
    ...mapState(["buttonLoading"]),
    toRoute() {
      return `/product/${this.$route.params.id}/edit?company=${this.$route.query.company}`;
    }
  },
  methods: mapActions(["confirmationAlert"])
};
</script>
