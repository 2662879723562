<template>
  <Card displayControls title="Interests Dashboard" interests>
    <v-list class="px-6" three-line>
      <NoItems
        v-if="!getProductInterests.length"
        :isPopulated="!getProductInterests.length"
        items
      >
        No Interests
      </NoItems>
      <v-list-item-group v-else>
        <v-list-item
          v-for="interest in getProductInterests"
          :key="interest.id"
          :ripple="false"
          inactive
        >
          <v-list-item-content color="white">
            <v-list-item inactive disbled class="d-flex pa-0">
              <v-list-item-avatar
                tile
                size="120"
                color="grey lighten-4"
                class="rounded-tr-xl"
              >
                <v-img v-if="interest.url" :src="interest.url"></v-img>
                <v-icon color="midnightGreen" size="80" v-else
                  >account_balance</v-icon
                >
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title color="#2B2D42" class="text-h6">
                  {{ interest.company_name }}
                  <v-list-item-subtitle class="pt-4">
                    {{ interest.location }}
                  </v-list-item-subtitle>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item-title class="text-h6 font-weight-normal"
              >{{ interest.name }}
            </v-list-item-title>
            <v-list-item-title
              class="text-h6 font-weight-normal blueGreen--text"
              >KES {{ interest.min_price }}
            </v-list-item-title>
            <v-list-item-subtitle>
              per {{ interest.unit | lowercase }} &#9679; Min Order
              {{ interest.quantity }} {{ interest.unit | lowercase }}
            </v-list-item-subtitle>
            <v-list-item-title class="text-subtitle-1">
              {{ interest.delivery_cost }}</v-list-item-title
            >
            <v-card flat class="d-flex justify-content-between pt-3">
              <v-btn
                v-if="interest.status === 'accepted'"
                depressed
                text
                class="text-subtitle-2 text-capitalize green--text mr-5"
              >
                <v-icon size="25" color="green"
                  >mdi-checkbox-marked-circle-outline</v-icon
                >Request accepted
              </v-btn>
              <v-btn
                v-else
                color="green"
                class="mr-5 text-subtitle-1 text-capitalize"
                dark
                width="40%"
                @click="
                  acceptInterest({
                    interestId: interest.id,
                    productId: $route.query.product
                  })
                "
                >Accept</v-btn
              >
              <v-btn
                v-if="interest.status === 'declined'"
                depressed
                text
                class="text-subtitle-2 text-capitalize red--text inline-block"
              >
                <v-icon size="25" color="red"
                  >mdi-checkbox-marked-circle-outline</v-icon
                >Request declined
              </v-btn>
              <v-btn
                v-else
                color="red"
                class="text-capitalize text-subtitle-1"
                dark
                width="40%"
                @click="
                  declineInterest({
                    interestId: interest.id,
                    productId: $route.query.product
                  })
                "
                >Decline</v-btn
              >
            </v-card>
          </v-list-item-content>
        </v-list-item>
        <v-divider inset></v-divider>
      </v-list-item-group>
    </v-list>
  </Card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import Card from "@/components/Card";
import NoItems from "@/components/NoItems";

export default {
  name: "InterestsDashboard",
  components: {
    Card,
    NoItems
  },
  async created() {
    await this.setIsLoading();
    await this.fetchInterestsByProductId(this.$route.query.product);
    await this.setLoaded();
  },
  computed: {
    ...mapGetters(["getProductInterests"])
  },
  methods: {
    ...mapActions([
      "fetchInterestsByProductId",
      "acceptInterest",
      "declineInterest"
    ]),
    ...mapMutations(["setIsLoading", "setLoaded"])
  }
};
</script>
