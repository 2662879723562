<template>
  <Success v-if="getIsSubmitted" />
  <BackgroundFixed v-else>
    <ValidationObserver v-slot="{ invalid }">
      <v-card flat class="card">
        <v-card-title class="blueGreen--text text-h4 font-weight-bold px-3">
          Did you forget your password?
        </v-card-title>
        <v-card-text class="text-body-1 px-3"
          >Confirm your email to receive a link!</v-card-text
        >
        <ValidationProvider
          name="Email"
          rules="required|email"
          v-slot="{ errors }"
        >
          <v-text-field
            class="input px-3"
            v-model="email"
            :error-messages="errors"
            label="Your Email"
            required
            @keypress.enter="
              forgotPassword({ email, url: `${fetchUrl}/password-update` })
            "
          ></v-text-field>
        </ValidationProvider>
        <v-btn
          color="#008f8f"
          large
          class="mx-2 font-weight-bold text-h5 white--text text-capitalize"
          :disabled="invalid"
          @click="forgotPassword({ email, url: `${fetchUrl}/password-update` })"
          >Submit</v-btn
        >
        <router-link to="/login">
          <v-card-text class="back text-center text-subtitle-1"
            >Back</v-card-text
          >
        </router-link>
      </v-card>
    </ValidationObserver>
  </BackgroundFixed>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import Success from "@/components/Success";
import BackgroundFixed from "@/components/BackgroundFixed";

export default {
  name: "ForgotPassword",
  components: {
    Success,
    BackgroundFixed
  },
  data() {
    return {
      email: ""
    };
  },
  computed: {
    fetchUrl() {
      return process.env.VUE_APP_FRONT_END_URL;
    },
    ...mapGetters(["getIsSubmitted"])
  },
  methods: mapActions(["forgotPassword"])
};
</script>

<style scoped>
.input {
  margin-top: 15%;
  margin-bottom: 10%;
}

.back:hover {
  text-decoration: none;
  color: #ff5666;
}
</style>
