<template>
  <SelectPreferenceTags v-if="emptyPreferences && isLoggedIn" />
  <Card
    displayControls
    title="Training"
    notifications
    v-else
    classes="px-7 pt-5"
  >
    <v-alert dense border="left" type="warning" v-if="!isLoggedIn">
      <router-link to="/login">
        You are viewing Jukua as a guest.
        <strong>Sign in/Sign up</strong>
      </router-link>
    </v-alert>
    <v-alert
      dense
      border="left"
      type="warning"
      v-if="isLoggedIn && !myCompanies.length"
    >
      You do not have any companies registered yet.
      <router-link to="/register-onboarding"
        ><strong>Register Now!</strong></router-link
      >
    </v-alert>
    <v-card flat v-if="!emptyPreferences">
      <v-card-title class="pa-0 text-h5 font-weight-bold midnightGreen--text">
        Our Recommended Courses
      </v-card-title>
      <v-row>
        <v-col
          xl="4"
          lg="6"
          md="6"
          sm="6"
          cols="12"
          v-for="filteredContent in filteredPreferences"
          :key="filteredContent.id"
        >
          <v-card router :to="`/training/${filteredContent.id}`" flat tile>
            <v-img
              class="rounded-tr-xl elevation-2"
              height="150"
              :src="filteredContent.feature_image"
            ></v-img>
            <v-card-text class="px-0 pt-3">
              <v-card-title class="pa-0 text-h6 font-weight-bold text-break">{{
                filteredContent.title
              }}</v-card-title>
              <v-card-text class="pa-0">{{
                filteredContent.excerpt
              }}</v-card-text>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-card flat class="pb-10">
      <v-card-title class="pa-0 text-h5 font-weight-bold midnightGreen--text">
        Our Available Courses
      </v-card-title>
      <v-text-field
        v-model="searchTrainingContent"
        label="Search for a course or event"
        prepend-inner-icon="mdi-magnify"
      ></v-text-field>
      <div class="flex-wrap">
        <v-btn
          v-for="(tag, index) in trainingTags"
          :key="index"
          class="mb-2 mr-2 text-caption"
          @click="filterByTag($event)"
          :name="tag.name"
          depressed
          large
          >{{ tag.name }}</v-btn
        >
      </div>
      <v-row>
        <v-col
          xl="4"
          lg="6"
          md="6"
          sm="6"
          cols="12"
          v-for="content in queryTrainingContent"
          :key="content.id"
          outlined
          tile
        >
          <v-card router :to="`/training/${content.id}`" flat>
            <v-img
              class="rounded-tr-xl elevation-2"
              height="150"
              :src="content.feature_image"
            ></v-img>

            <v-card-text class="px-0 pt-3">
              <v-card-title class="pa-0 text-h6 font-weight-bold text-break">{{
                content.title
              }}</v-card-title>
              <v-card-text class="pa-0 text-muted">{{
                content.published_at
              }}</v-card-text>

              <v-card-text class="pa-0">{{ content.excerpt }}</v-card-text>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <Pagination
        v-if="pages.training && !this.searchTrainingContent"
        :currentPage="pages.training.currentPage"
        :lastPage="pages.training.lastPage"
        :fetchNextFunction="fetchTrainingContent"
      />
    </v-card>
  </Card>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";

import Card from "@/components/Card";
import SelectPreferenceTags from "@/components/SelectPreferenceTags";
import Pagination from "@/components/Pagination";

export default {
  name: "Training",
  components: {
    Card,
    SelectPreferenceTags,
    Pagination
  },
  data() {
    return {
      searchTrainingContent: null,
      trainingContentChanged: false
    };
  },
  async created() {
    await this.setIsLoading();
    await this.fetchTags();
    await this.fetchTrainingContent();
    await this.filterByPreferences();
    await this.setLoaded();
  },
  watch: {
    trainingContent: {
      handler(newTrainingContent, oldTrainingContent) {
        if (
          JSON.stringify(oldTrainingContent) ===
          JSON.stringify(newTrainingContent)
        ) {
          this.trainingContentChanged = false;
          return;
        }

        this.trainingContentChanged = true;
      },
      deep: true
    }
  },
  computed: {
    ...mapState([
      "myCompanies",
      "trainingTags",
      "trainingContent",
      "filteredTrainingContent",
      "filteredPreferences",
      "preferenceTags",
      "emptyPreferences",
      "pages",
      "filteredTrainingContentChanged"
    ]),
    ...mapGetters(["isLoggedIn"]),
    queryTrainingContent() {
      if (this.searchTrainingContent) {
        return this.trainingContent.filter(content =>
          content.title
            .toLowerCase()
            .includes(this.searchTrainingContent.toLowerCase())
        );
      }

      if (this.filteredTrainingContentChanged) {
        return this.filteredTrainingContent;
      }

      if (this.trainingContentChanged) {
        this.changeTrainingContent();
        return this.trainingContent;
      }

      return this.trainingContent;
    }
  },
  methods: {
    ...mapActions([
      "fetchTrainingContent",
      "fetchTags",
      "filterByTag",
      "filterByPreferences"
    ]),
    ...mapMutations(["setIsLoading", "setLoaded"]),
    changeTrainingContent() {
      this.trainingContentChanged = false;
    },
    changeFilteredTrainingContent() {
      this.filteredTrainingContentChanged = false;
    }
  }
};
</script>
