<template>
  <nav>
    <v-app-bar
      flat
      color="transparent"
      v-if="$route.path !== '/' && $route.path !== '/faqs'"
      app
    >
      <v-btn fab small depressed icon @click="drawer = !drawer">
        <v-img
          :class="$vuetify.breakpoint.mobile ? 'menu' : ''"
          src="@/assets/menu.svg"
        />
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer
      width="350"
      absolute
      temporary
      v-model="drawer"
      color="white"
      hide-overlay
    >
      <v-list>
        <v-row no-gutters class="mx-4 my-3 mb-10">
          <v-col align="left">
            <v-icon color="midnightGreen" @click="drawer = !drawer"
              >mdi-close</v-icon
            >
          </v-col>
        </v-row>
        <v-list-item-group>
          <v-list-item
            class="mt-5"
            v-for="link in linksToDisplay"
            link
            :key="link.text"
            router
            :to="link.route"
          >
            <v-list-item-content>
              <v-list-item-title
                class="midnightGreen--text font-weight-bold text-h6"
                >{{ link.text }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Navbar",
  data() {
    return {
      forgotPassword: false,
      drawer: false,
      loggedOutLinks: [
        { text: "Home", route: "/" },
        { text: "Dashboard", route: "/dashboard" },
        { text: "FAQs", route: "/faqs" },
        { text: "Sign Up", route: "/register" },
        { text: "Sign In", route: "/login" },
        { text: "Training", route: "/training" }
      ],
      loggedInLinks: [
        { text: "Home", route: "/" },
        { text: "Dashboard", route: "/dashboard" },
        { text: "FAQs", route: "/faqs" },
        { text: "Profile", route: "/profile" },
        { text: "Notifications", route: "/notifications" },
        { text: "Interests", route: "/interests" },
        { text: "Training", route: "/training" },
        { text: "Logout", route: "/logout" }
      ]
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn"]),
    linksToDisplay() {
      return this.isLoggedIn ? this.loggedInLinks : this.loggedOutLinks;
    }
  }
};
</script>

<style scoped>
.v-application .v-list-item--active {
  text-decoration: underline;
  text-decoration-color: #ff5666;
  text-decoration-thickness: 4px !important;
  background: white !important;
}
</style>
