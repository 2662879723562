<template>
  <Card
    displayControls
    title="Edit Product"
    pathTo
    :location="
      () =>
        $router.push(
          `/product/${$route.params.id}/delete?company=${$route.query.company}`
        )
    "
  >
    <ProductImage :product="product" editable />
    <ImageGrid :productImages="productImages" />
    <ValidationObserver
      class="d-flex flex flex-column justify-space-between"
      ref="observer"
      v-slot="{ invalid, changed }"
    >
      <v-form class="px-10" v-if="product">
        <ValidationProvider
          name="Product name"
          rules="min:3"
          v-slot="{ errors }"
        >
          <v-text-field
            v-model="formItems.name"
            label="Product Name"
            :error-messages="errors"
            :placeholder="product.name"
            required
          ></v-text-field>
        </ValidationProvider>
        <ValidationProvider name="Category" rules="min:3" v-slot="{ errors }">
          <v-select
            v-model="formItems.category"
            :items="categories"
            dense
            label="Category"
            :error-messages="errors"
            :placeholder="product.category"
            required
          ></v-select>
        </ValidationProvider>
        <ValidationProvider
          v-if="formItems.category === 'Other'"
          name="Other Category"
          rules="min:3"
          v-slot="{ errors }"
        >
          <v-text-field
            v-model="formItems.category_other"
            label="Other category"
            :error-messages="errors"
            type="text"
          ></v-text-field>
        </ValidationProvider>
        <ValidationProvider
          name="Unit of measurement"
          mode="eager"
          rules="min:2"
          v-slot="{ errors }"
        >
          <v-select
            class="input-field"
            type="text"
            v-model="formItems.unit"
            :items="units"
            dense
            :error-messages="errors"
            label="Unit of Measurement"
            :placeholder="product.unit"
            required
          ></v-select>
        </ValidationProvider>
        <ValidationProvider
          name="Unit of measurement"
          v-if="formItems.unit === 'Other'"
          rules="min:2"
          v-slot="{ errors }"
        >
          <v-text-field
            v-model="formItems.unit_other"
            label="Other unit of measurement"
            :error-messages="errors"
            class="input"
            type="text"
          ></v-text-field>
        </ValidationProvider>
        <ValidationProvider name="Quantity" rules="numeric" v-slot="{ errors }">
          <v-text-field
            v-model.number="formItems.quantity"
            :label="`Quantity ${retrieveUnitType}`"
            :error-messages="errors"
            :placeholder="product.quantity"
            class="input"
            type="numeric"
          ></v-text-field>
        </ValidationProvider>
        <ValidationProvider
          name="Location of product"
          rules="min:4"
          v-slot="{ errors }"
        >
          <v-text-field
            v-model="formItems.location"
            label="Location of Product"
            :error-messages="errors"
            :placeholder="product.location"
            class="input"
            type="text"
          ></v-text-field>
        </ValidationProvider>
      </v-form>
      <v-list class="px-6" v-if="pricings" three-line nav>
        <v-list-item-group v-for="price in pricings" :key="price.id">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item
                v-bind="attrs"
                v-on="on"
                router
                :to="{
                  name: 'ProductPricingUpdate',
                  params: {
                    productId: $route.params.id,
                    pricingId: price.id,
                    redirectToRoute: $route.fullPath
                  },
                  query: {
                    company: $route.query.company
                  }
                }"
              >
                <v-list-item-content>
                  <v-list-item-title
                    class="text-h6 font-weight-normal blueGreen--text"
                    >{{ price.min_price }} KES - {{ price.max_price }} KES
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    per {{ price.unit | lowercase }} &#9679; Min. Order
                    {{ price.min_unit }} {{ price.unit | lowercase }}
                  </v-list-item-subtitle>
                  <v-list-item-title class="text-subtitle-1">
                    {{ price.delivery_cost }}</v-list-item-title
                  >
                </v-list-item-content>
                <v-icon fab color="#F8B234">mdi-pencil</v-icon>
              </v-list-item>
            </template>
            <span>Edit pricing</span>
          </v-tooltip>
          <v-divider inset></v-divider>
        </v-list-item-group>
      </v-list>
      <v-card flat class="d-flex align-items-center px-5 pb-5">
        <v-icon size="30" color="#35A19E">mdi-plus-circle</v-icon>
        <span
          @click="
            $router.push({
              name: 'ProductPricing',
              params: {
                productId: $route.params.id,
                companyId: $route.query.company,
                redirectToRoute: $route.fullPath
              }
            })
          "
          class="midnightGreen--text delete--button text-decoration-underline text-capitalize text-h6 font-weight-normal"
          >Add Another Product Price</span
        >
      </v-card>
      <BaseButton
        buttonColor="blueGreen"
        textColor="white"
        :loading="buttonLoading"
        :invalid="isUpdated(invalid, changed)"
        @click.native="submit"
        >Save</BaseButton
      >
    </ValidationObserver>
  </Card>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

import Card from "@/components/Card";
import BaseButton from "@/components/BaseButton";
import ProductImage from "@/components/ProductImage";
import ImageGrid from "@/components/ImageGrid";
import { filterObjectMixin } from "@/mixins/filterObjectMixin";

const initialState = () => ({
  formItems: {
    name: "",
    category: "",
    category_other: "",
    unit: "",
    unit_other: "",
    quantity: "",
    location: ""
  },
  categories: [
    "Agriculture",
    "Beauty",
    "Electronics",
    "Fashion",
    "Gaming",
    "Health",
    "Finance",
    "Sporting Goods",
    "Materials",
    "Phones & Tablets",
    "Computing",
    "Other"
  ],
  units: [
    "Tonne",
    "Kg",
    "Grams",
    "Foot",
    "Litres",
    "Crates",
    "Cartons",
    "Dozens",
    "Metre",
    "Hour",
    "Minute",
    "Container",
    "Session",
    "Unit",
    "Other"
  ]
});

export default {
  name: "EditProductOrPricing",
  mixins: [filterObjectMixin],
  components: {
    Card,
    BaseButton,
    ProductImage,
    ImageGrid
  },
  data: () => initialState(),
  async created() {
    await this.setIsLoading();
    await this.fetchProductById(this.$route.params.id);
    await this.fetchProductImagesById(this.$route.params.id);
    await this.fetchPricingByProductId(this.$route.params.id);
    await this.setLoaded();
  },
  computed: {
    ...mapState(["productImages", "product", "pricings", "buttonLoading"]),
    retrieveUnitType() {
      if (this.formItems.unit || this.formItems.unit_other) {
        const unitType = this.formItems.unit ?? this.formItems.unit_other;

        return `(${unitType})`;
      }

      if (this.product.unit) {
        return `(${this.product.unit})`;
      }

      return "";
    }
  },
  methods: {
    ...mapActions([
      "fetchProductById",
      "fetchProductImagesById",
      "fetchPricingByProductId",
      "updateProduct"
    ]),
    ...mapMutations([
      "setIsLoading",
      "setLoaded",
      "setButtonLoading",
      "setButtonLoaded"
    ]),
    isUpdated(invalid, changed) {
      if (!invalid && changed) {
        return false;
      }

      return true;
    },
    async submit() {
      this.setButtonLoading();

      const filteredValues = this.filterEmptyFormValues(this.formItems);

      if (this.objectIsFilled(filteredValues)) {
        await this.updateProduct({
          productId: this.$route.params.id,
          data: filteredValues
        });

        this.resetData();
      }

      this.setButtonLoaded();
    },
    resetData() {
      Object.assign(this.$data, initialState());
    }
  }
};
</script>
