export const clearLastRouteMixin = {
  methods: {
    async goBackAndClearLastRoute() {
      if (Object.keys(this.redirectRoute).length) {
        const redirectToRoute = await this.extractRedirectRouteAndFilterSavedReference();
        return this.$router.push(redirectToRoute);
      }
    },
    async extractRedirectRouteAndFilterSavedReference() {
      const currentRoute = this.$route.fullPath;
      const redirectToRoute = this.redirectRoute[currentRoute];
      await this.filterRedirectRoute(currentRoute);
      return redirectToRoute || null;
    }
  }
};
