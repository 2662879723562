<template>
  <div class="d-flex flex flex-column align-center justify-space-between">
    <CenteredLogo />
    <Card classes="pt-10 px-10" hide cardHeight="80%" cardWidth="50%">
      <h1 class="mb-md-10 midnightGreen--text">Sign In</h1>
      <ValidationObserver ref="obs" v-slot="{ invalid }">
        <v-form>
          <ValidationProvider
            name="Email"
            mode="eager"
            rules="required|email"
            v-slot="{ errors }"
          >
            <v-text-field
              type="email"
              v-model="email"
              :error-messages="errors"
              label="Email"
              required
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            name="Password"
            rules="required|min:5|max:36"
            v-slot="{ errors }"
          >
            <v-text-field
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              v-model="password"
              :error-messages="errors"
              @keypress.enter="login({ email, password })"
              label="Password"
              required
            ></v-text-field>
          </ValidationProvider>
        </v-form>
        <v-card
          tile
          flat
          class="text-h6 d-flex justify-space-between align-center pt-3 pb-3"
        >
          <v-card-title class="pa-0 midnightGreen--text">Sign In</v-card-title>
          <v-btn
            class="mx-2"
            fab
            large
            color="primary"
            @click="login({ email, password })"
            :disabled="invalid"
          >
            <v-icon color="black">mdi-arrow-right</v-icon>
          </v-btn>
        </v-card>
        <v-card tile flat class="d-flex justify-space-between align-center">
          <router-link
            class="text-h6 text-underline-offset loginActions"
            to="/register"
            >Sign up</router-link
          >
          <router-link
            class="text-h6 text-underline-offset loginActions"
            to="/forgot-password"
            >Forgot your password?</router-link
          >
        </v-card>
      </ValidationObserver>
    </Card>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import CenteredLogo from "@/components/CenteredLogo";
import Card from "@/components/Card";

export default {
  name: "Login",
  components: {
    CenteredLogo,
    Card
  },
  data() {
    return {
      email: "",
      password: "",
      showPassword: false
    };
  },

  methods: mapActions(["login"])
};
</script>

<style scoped>
.card-container {
  height: 60%;
}

.text-underline-offset {
  border-style: solid;
  border-width: 0;
  border-bottom-width: 1.8px;
}

h1 {
  font-weight: 600;
}

@media only screen and (max-width: 441px) {
  .loginActions {
    font-size: 12px;
    margin-bottom: 20%;
  }
}
</style>
