export const state = {
  next: false,
  alerts: null,
  token: null,
  verificationToken: null,
  isLoading: false,
  buttonLoading: false,
  isSubmitted: false,
  isInterested: false,
  fileToUpload: null,
  previewImage: null,
  registered: false,
  isEmpty: false,
  product: [],
  profile: {},
  companies: [],
  searchedCompanies: [],
  myCompanies: [],
  mySearchedCompanies: [],
  company: null,
  interests: [],
  products: [],
  searchedProducts: [],
  categories: [],
  searchedCategories: [],
  trainingTags: [],
  trainingContent: [],
  trainingContentById: {
    title: null,
    html: null
  },
  companyProducts: [],
  companyForm: {
    name: null,
    email: null,
    type: null,
    display_number: null,
    phone_number: null,
    address: null,
    physical_location: null,
    business_regno: null,
    kra_pin: null
  },
  pricing: {},
  pricings: [],
  filteredTrainingContent: [],
  filteredTrainingContentChanged: false,
  filteredPreferences: [],
  preferenceTags: [],
  emptyPreferences: true,
  notifications: [],
  productInterests: [],
  pages: {},
  productImages: [],
  redirectRoute: Object.create(null)
};
