<template>
  <v-btn
    :to="to"
    tile
    :class="
      `${
        textColor ? `${textColor}--text` : 'midnightGreen--text'
      } py-10 text-h6 text-capitalize font-weight-bold rounded-corner`
    "
    :width="width"
    large
    :loading="loading"
    :color="switchButtonColor"
    :disabled="invalid"
  >
    <slot />
  </v-btn>
</template>

<script>
import { widthMixin } from "@/mixins/widthMixin";

export default {
  name: "BaseButton",
  mixins: [widthMixin],
  props: {
    textColor: String,
    buttonColor: String,
    invalid: Boolean,
    loading: Boolean,
    to: [String, Object, Promise]
  },
  data: () => ({
    smallScreen: "100%",
    largeScreen: "35%"
  }),
  computed: {
    switchButtonColor() {
      return this.buttonColor ? this.buttonColor : "#F8B234";
    }
  }
};
</script>

<style scoped>
.rounded-corner {
  border-top-right-radius: 30px;
}
</style>
