<template>
  <div v-if="length > 1" class="flex justify-center">
    <v-pagination
      color="midnightGreen"
      v-model="page"
      :length="length"
      circle
    />
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    currentPage: Number,
    lastPage: Number,
    fetchNextFunction: Function,
    searchTerm: String
  },
  computed: {
    page: {
      get() {
        return this.currentPage;
      },
      set(nextPage) {
        this.fetchNextFunction({ query: this.searchTerm, page: nextPage });
      }
    },
    length() {
      return this.lastPage;
    }
  }
};
</script>
