import Vue from "vue";

export const mutations = {
  setRegistered: state => {
    state.registered = !state.registered;
  },
  setToken: (state, token) => {
    state.token = token;
  },
  setVerificationToken: (state, verificationToken) => {
    state.verificationToken = verificationToken;
  },
  setIsLoading: state => {
    state.isLoading = true;
  },
  setLoaded: state => {
    state.isLoading = false;
  },
  setAlert: (state, alerts) => {
    state.alerts = alerts;
  },
  logout(state) {
    state.token = null;
  },
  setIsSubmitted: state => {
    state.isSubmitted = !state.isSubmitted;
  },
  setProfile: (state, profile) => {
    state.profile = profile;
  },
  setCreateCompanyForm: (state, form) => {
    const filterEmptyFormValues = Object.entries(form).filter(formData => {
      return !!formData[1];
    });

    const filteredObjected = Object.fromEntries(filterEmptyFormValues);

    state.companyForm = filteredObjected;
  },
  setCompanyRegistrationForm: (state, form) => {
    state.companyForm = form;
  },
  setCompany: (state, company) => {
    state.company = company;
  },
  setCompanies: (state, companies) => {
    state.companies = companies;
  },
  setMyCompanies: (state, myCompanies) => {
    state.myCompanies = myCompanies;
  },
  setProducts: (state, products) => {
    state.products = products;
  },
  setCompanyProducts: (state, companyProducts) => {
    state.companyProducts = companyProducts;
  },
  setProduct: (state, product) => {
    state.product = product;
  },
  setProductImagesEmpty: state => {
    state.productImages = [];
  },
  setProductImages: (state, productImages) => {
    state.productImages = productImages;
  },
  setPricing: (state, pricing) => {
    state.pricing = pricing;
  },
  setPricings: (state, pricings) => {
    state.pricings = pricings;
  },
  setInterests: (state, interests) => {
    state.interests = interests;
  },
  setProductInterests: (state, productInterests) => {
    state.productInterests = productInterests;
  },
  setIsInterested: state => {
    state.isInterested = !state.isInterested;
  },
  setValueOfIsInterested: (state, isInterested) => {
    state.isInterested = isInterested;
  },
  setPreviewImage: (state, previewImage) => {
    state.previewImage = previewImage;
  },
  setFileToUpload: (state, fileToUpload) => {
    state.fileToUpload = fileToUpload;
  },
  setTrainingContent: (state, trainingContent) => {
    state.trainingContent = trainingContent;
  },
  setTrainingContentById: (state, trainingContentById) => {
    state.trainingContentById = trainingContentById;
  },
  setFilteredTraining: (state, filteredTrainingContent) => {
    state.filteredTrainingContent = filteredTrainingContent;
  },
  setFilteredPreferences: (state, filteredPreferences) => {
    state.filteredPreferences = filteredPreferences;
  },
  setFilteredTrainingChanged: state => {
    state.filteredTrainingContentChanged = !state.filteredTrainingContentChanged;
  },
  setFilteredTrainingChangedToFalse: state => {
    state.filteredTrainingContentChanged = false;
  },
  setTags: (state, trainingTags) => {
    state.trainingTags = trainingTags;
  },
  setPreferenceTags: (state, preferenceTags) => {
    state.preferenceTags = preferenceTags;
  },
  emptyPreferenceTags: (state, emptyPreferences) => {
    state.emptyPreferences = emptyPreferences;
  },
  setNext: state => {
    state.next = !state.next;
  },
  setNextToFalse: state => {
    state.next = false;
  },
  setNotifications: (state, notifications) => {
    state.notifications = notifications;
  },
  setCategories: (state, categories) => {
    state.categories = categories;
  },
  setPages: (state, pages) => {
    state.pages = { ...state.pages, ...pages };
  },
  setSearchedProducts: (state, searchedProducts) => {
    state.searchedProducts = searchedProducts;
  },
  setSearchedCategories: (state, searchedCategories) => {
    state.searchedCategories = searchedCategories;
  },
  setSearchedCompanies: (state, searchedCompanies) => {
    state.searchedCompanies = searchedCompanies;
  },
  setMySearchedCompanies: (state, mySearchedCompanies) => {
    state.mySearchedCompanies = mySearchedCompanies;
  },
  setRedirectRoute: (state, { currentUrl, redirectRoute }) => {
    Vue.set(state.redirectRoute, currentUrl, redirectRoute);
  },
  resetRedirectRoutes: state => {
    state.redirectRoute = Object.create(null);
  },
  filterRedirectRoute: (state, redirectRoute) => {
    const redirectRoutes = state.redirectRoute;

    const lastIndex = Object.keys(redirectRoutes).length - 1;
    const redirectRouteIndex = Object.keys(redirectRoutes).findIndex(
      route => route === redirectRoute
    );

    if (redirectRouteIndex === lastIndex) {
      delete redirectRoutes[redirectRoute];
      state.redirectRoute = redirectRoutes;
      return;
    }

    const filteredRoutes = Object.entries(redirectRoutes).slice(
      0,
      redirectRouteIndex
    );
    state.redirectRoute = Object.fromEntries(filteredRoutes);
  },
  setButtonLoading: state => {
    state.buttonLoading = true;
  },
  setButtonLoaded: state => {
    state.buttonLoading = false;
  }
};
