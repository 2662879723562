<template>
  <BottomCard
    :icon="confirmDelete ? 'error' : ''"
    iconColor="pink"
    @toggledSheet="sheetClosed"
  >
    <template v-slot:button="{ on, attrs }">
      <slot :attrs="attrs" :on="on" />
    </template>
    <template v-slot:content>
      <v-card-title class="text-h5 midnightGreen--text font-weight-bold mb-2"
        >Delete Profile</v-card-title
      >
      <v-card-subtitle
        v-if="!confirmDelete"
        class="text-subtitle-1 midnightGreen--text"
        >Are you sure you want to delete your Jukua account?</v-card-subtitle
      >
      <v-card-subtitle v-else class="text-subtitle-1 midnightGreen--text"
        >You will permanently lose your account data. There is no turning back
        after this!</v-card-subtitle
      >
      <v-btn
        class="font-weight-bold py-8 text-h6 text-capitalize midnightGreen--text"
        large
        width="100%"
        color="primary"
        @click="confirmDelete = !confirmDelete"
        dark
        v-if="!confirmDelete"
      >
        Continue
      </v-btn>
      <v-card-text>
        <ValidationObserver
          class="flex d-flex flex-column justify-space-between"
          ref="obs"
          v-slot="{ invalid }"
        >
          <v-row>
            <v-col cols="12" class="my-4" v-if="confirmDelete">
              <ValidationProvider
                rules="required|min:5|max:36"
                name="Password"
                vid="confirm"
                v-slot="{ errors }"
              >
                <v-text-field
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword"
                  v-model="password"
                  class="my-1"
                  label="Password"
                  :error-messages="errors"
                  required
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider
                rules="required|confirmed:confirm"
                name="Password confirmation"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="passwordConfirmed"
                  class="my-1"
                  label="Confirm Password"
                  type="password"
                  :error-messages="errors"
                  required
                ></v-text-field>
              </ValidationProvider>
              <v-btn
                class="font-weight-bold py-8 text-h6 text-capitalize white--text"
                large
                width="100%"
                color="pink"
                :disabled="invalid"
                @click="deleteProfile({ password })"
              >
                Confirm Deletion
              </v-btn>
            </v-col>
          </v-row>
        </ValidationObserver>
      </v-card-text>
    </template>
  </BottomCard>
</template>

<script>
import { mapActions } from "vuex";

import BottomCard from "@/components/BottomCard";

export default {
  name: "DeleteProfile",
  components: {
    BottomCard
  },
  data() {
    return {
      password: "",
      passwordConfirmed: "",
      confirmDelete: false,
      showPassword: false
    };
  },
  methods: {
    ...mapActions(["deleteProfile"]),
    sheetClosed(sheetState) {
      this.confirmDelete = sheetState;
    }
  }
};
</script>
