var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Card',{attrs:{"displayControls":"","pathTo":"","location":_vm.redirectToProfileOrDashboard,"title":_vm._f("capitalize")(_vm.outputCompanyName),"editable":_vm.mine,"editMessage":"Edit company","to":("/company/" + (_vm.$route.params.id) + "/edit")}},[_c('div',{staticClass:"px-5 pt-5 d-flex flex-row align-items-center"},[_c('v-avatar',{staticClass:"mr-3 mb-1",attrs:{"size":_vm.width,"color":"grey lighten-2"}},[(_vm.company && _vm.company.url)?_c('v-img',{attrs:{"src":_vm.company.url}}):_c('v-icon',{staticClass:"v-icon",attrs:{"size":"90"}},[_vm._v("mdi-account")])],1),(_vm.company)?_c('v-col',{staticClass:"pa-0"},[_c('div',{staticClass:"d-flex"},[_c('h3',{staticClass:"font-weight-bold midnightGreen--text pr-2"},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.company.name))+" ")]),(_vm.company.status === 'verified')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20","color":"green","tile":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-shield-check ")])]}}],null,false,698988789)},[_c('span',[_vm._v("Business verified")])]):_vm._e(),(_vm.company.status === 'unverified')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20","color":"red","tile":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-shield-alert ")])]}}],null,false,4089271061)},[_c('span',[_vm._v("Business not verified")])]):_vm._e()],1),_c('p',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.company.type))]),_c('v-chip',{staticClass:"text-uppercase",attrs:{"small":"","color":"secondaryNeutral","text-color":"midnightGreen","label":""}},[_vm._v(_vm._s(_vm.company.physical_location))])],1):_vm._e()],1),_c('v-divider',{attrs:{"inset":""}}),_c('v-card',{staticClass:"px-5 flex",attrs:{"flat":""}},[_c('h4',{staticClass:"font-weight-bold midnightGreen--text"},[_vm._v(" "+_vm._s(_vm.mine ? "My Products" : "Products")+" ")]),(!_vm.companyProducts.length)?_c('v-row',{staticClass:"mx-4 justify-center"},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"auto"}},[_c('v-img',{attrs:{"src":require("@/assets/noProducts.png")}}),_c('p',{staticClass:"text-h6 midnightGreen--text font-weight-bold"},[_vm._v(" No products uploaded yet ")])],1)],1):_c('v-row',_vm._l((_vm.companyProducts),function(product){return _c('v-col',{key:product.id,attrs:{"lg":"6","md":"6","sm":"12","cols":"12"}},[_c('router-link',{attrs:{"to":{
            name: 'Product',
            params: {
              id: product.id,
              redirectToRoute: _vm.$route.fullPath
            },
            query: {
              company: _vm.$route.params.id
            }
          }}},[_c('v-list-item-content',[_c('v-list-item-avatar',{staticClass:"rounded-tr-xl elevation-1",attrs:{"tile":"","size":"200"}},[(product.url)?_c('v-img',{attrs:{"src":product.url}}):_c('v-img',{attrs:{"src":require("@/assets/company-placeholder.svg")}})],1),_c('v-list-item-title',{staticClass:"text-h6 font-weight-normal"},[_vm._v(_vm._s(product.name))]),_c('v-list-item-title',{staticClass:"grey--text"},[_vm._v(_vm._s(product.min_price)+" KES to "+_vm._s(product.max_price)+" KES per "+_vm._s(_vm._f("lowercase")(product.unit)))])],1)],1)],1)}),1)],1),(_vm.mine)?_c('BaseButton',{attrs:{"to":("/products/" + (_vm.$route.params.id))}},[_vm._v("Upload Products")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }