<template>
  <Card
    classes="px-10 pt-4"
    displayControls
    :title="getTrainingContentById.title"
  >
    <v-card flat tile>
      <h1 class="mt-4">{{ getTrainingContentById.title }}</h1>
      <p v-html="getTrainingContentById.html"></p>
    </v-card>
  </Card>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";

import Card from "@/components/Card";

export default {
  name: "TrainingContent",
  components: {
    Card
  },
  computed: mapGetters(["getTrainingContentById"]),
  beforeDestroy() {
    this.setTrainingContentById({ html: null, title: null });
  },
  async created() {
    await this.setIsLoading();
    await this.fetchTrainingContentById(this.$route.params.id);
    await this.setLoaded();
  },
  methods: {
    ...mapActions(["fetchTrainingContentById"]),
    ...mapMutations(["setTrainingContentById", "setIsLoading", "setLoaded"])
  }
};
</script>

<style scoped>
div >>> h1,
div >>> h2,
div >>> h3,
div >>> h4,
div >>> h5,
div >>> h6 {
  font-weight: bold;
  color: #0c363d;
}

div >>> p a:hover {
  text-decoration: none;
  color: #fabc2a !important;
}

div >>> p a {
  text-decoration: underline;
  color: #0c363d !important;
}

div >>> figure iframe,
div >>> figure img {
  max-width: 85vw;
}
</style>
