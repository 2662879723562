<template>
  <img src="@/assets/logo beta white.svg" />
</template>

<script>
export default {
  name: "CenteredLogo"
};
</script>

<style scoped>
img {
  width: 30vh;
  margin: auto;
  display: block;
}
</style>
