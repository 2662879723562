<template>
  <Card displayControls title="Product Images">
    <v-card flat class="px-7 pt-7 flex">
      <h1 class="midnightGreen--text">
        Product Images
      </h1>
      <v-row v-if="productImages.length">
        <v-col
          lg="3"
          md="3"
          sm="6"
          cols="6"
          v-for="images in productImages"
          :key="images.id"
        >
          <v-list-item-content>
            <v-list-item-avatar
              tile
              size="130"
              class="rounded-tr-xl elevation-3"
            >
              <v-img :src="images.url"></v-img>
            </v-list-item-avatar>
          </v-list-item-content>
        </v-col>
      </v-row>
      <v-row class="pa-10 flex justify-center align-center" v-else>
        <v-progress-circular
          size="60"
          indeterminate
          color="primary"
          width="8"
        ></v-progress-circular>
      </v-row>
    </v-card>
    <BaseButton @click.native="redirectPathOnCompletion">Done</BaseButton>
  </Card>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

import Card from "@/components/Card";
import BaseButton from "@/components/BaseButton";
import { clearLastRouteMixin } from "@/mixins/clearLastRouteMixin";
import { checkRedirectRouteMixin } from "@/mixins/checkRedirectRouteMixin";

export default {
  name: "ProductImagesView",
  mixins: [clearLastRouteMixin, checkRedirectRouteMixin],
  props: {
    redirectToRoute: String
  },
  components: {
    Card,
    BaseButton
  },
  async created() {
    await this.setProductImagesEmpty();
    await this.fetchProductImagesById(this.$route.params.id);
    this.checkRedirectRoute();
  },
  computed: {
    ...mapState(["productImages", "redirectRoute"])
  },
  methods: {
    ...mapMutations([
      "setProductImagesEmpty",
      "setRedirectRoute",
      "filterRedirectRoute"
    ]),
    ...mapActions(["fetchProductImagesById"]),
    redirectPathOnCompletion() {
      if (Object.keys(this.redirectRoute).length) {
        return this.goBackAndClearLastRoute();
      }

      return this.$router.push(`/company/${this.$route.query.company}`);
    }
  }
};
</script>
