var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Card',{attrs:{"displayControls":"","title":"Edit Product","pathTo":"","location":function () { return _vm.$router.push(
        ("/product/" + (_vm.$route.params.id) + "/delete?company=" + (_vm.$route.query.company))
      ); }}},[_c('ProductImage',{attrs:{"product":_vm.product,"editable":""}}),_c('ImageGrid',{attrs:{"productImages":_vm.productImages}}),_c('ValidationObserver',{ref:"observer",staticClass:"d-flex flex flex-column justify-space-between",scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var invalid = ref.invalid;
      var changed = ref.changed;
return [(_vm.product)?_c('v-form',{staticClass:"px-10"},[_c('ValidationProvider',{attrs:{"name":"Product name","rules":"min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Product Name","error-messages":errors,"placeholder":_vm.product.name,"required":""},model:{value:(_vm.formItems.name),callback:function ($$v) {_vm.$set(_vm.formItems, "name", $$v)},expression:"formItems.name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Category","rules":"min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.categories,"dense":"","label":"Category","error-messages":errors,"placeholder":_vm.product.category,"required":""},model:{value:(_vm.formItems.category),callback:function ($$v) {_vm.$set(_vm.formItems, "category", $$v)},expression:"formItems.category"}})]}}],null,true)}),(_vm.formItems.category === 'Other')?_c('ValidationProvider',{attrs:{"name":"Other Category","rules":"min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Other category","error-messages":errors,"type":"text"},model:{value:(_vm.formItems.category_other),callback:function ($$v) {_vm.$set(_vm.formItems, "category_other", $$v)},expression:"formItems.category_other"}})]}}],null,true)}):_vm._e(),_c('ValidationProvider',{attrs:{"name":"Unit of measurement","mode":"eager","rules":"min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-select',{staticClass:"input-field",attrs:{"type":"text","items":_vm.units,"dense":"","error-messages":errors,"label":"Unit of Measurement","placeholder":_vm.product.unit,"required":""},model:{value:(_vm.formItems.unit),callback:function ($$v) {_vm.$set(_vm.formItems, "unit", $$v)},expression:"formItems.unit"}})]}}],null,true)}),(_vm.formItems.unit === 'Other')?_c('ValidationProvider',{attrs:{"name":"Unit of measurement","rules":"min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"label":"Other unit of measurement","error-messages":errors,"type":"text"},model:{value:(_vm.formItems.unit_other),callback:function ($$v) {_vm.$set(_vm.formItems, "unit_other", $$v)},expression:"formItems.unit_other"}})]}}],null,true)}):_vm._e(),_c('ValidationProvider',{attrs:{"name":"Quantity","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"label":("Quantity " + _vm.retrieveUnitType),"error-messages":errors,"placeholder":_vm.product.quantity,"type":"numeric"},model:{value:(_vm.formItems.quantity),callback:function ($$v) {_vm.$set(_vm.formItems, "quantity", _vm._n($$v))},expression:"formItems.quantity"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Location of product","rules":"min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"label":"Location of Product","error-messages":errors,"placeholder":_vm.product.location,"type":"text"},model:{value:(_vm.formItems.location),callback:function ($$v) {_vm.$set(_vm.formItems, "location", $$v)},expression:"formItems.location"}})]}}],null,true)})],1):_vm._e(),(_vm.pricings)?_c('v-list',{staticClass:"px-6",attrs:{"three-line":"","nav":""}},_vm._l((_vm.pricings),function(price){return _c('v-list-item-group',{key:price.id},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"router":"","to":{
                name: 'ProductPricingUpdate',
                params: {
                  productId: _vm.$route.params.id,
                  pricingId: price.id,
                  redirectToRoute: _vm.$route.fullPath
                },
                query: {
                  company: _vm.$route.query.company
                }
              }}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6 font-weight-normal blueGreen--text"},[_vm._v(_vm._s(price.min_price)+" KES - "+_vm._s(price.max_price)+" KES ")]),_c('v-list-item-subtitle',[_vm._v(" per "+_vm._s(_vm._f("lowercase")(price.unit))+" ● Min. Order "+_vm._s(price.min_unit)+" "+_vm._s(_vm._f("lowercase")(price.unit))+" ")]),_c('v-list-item-title',{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(price.delivery_cost))])],1),_c('v-icon',{attrs:{"fab":"","color":"#F8B234"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit pricing")])]),_c('v-divider',{attrs:{"inset":""}})],1)}),1):_vm._e(),_c('v-card',{staticClass:"d-flex align-items-center px-5 pb-5",attrs:{"flat":""}},[_c('v-icon',{attrs:{"size":"30","color":"#35A19E"}},[_vm._v("mdi-plus-circle")]),_c('span',{staticClass:"midnightGreen--text delete--button text-decoration-underline text-capitalize text-h6 font-weight-normal",on:{"click":function($event){return _vm.$router.push({
            name: 'ProductPricing',
            params: {
              productId: _vm.$route.params.id,
              companyId: _vm.$route.query.company,
              redirectToRoute: _vm.$route.fullPath
            }
          })}}},[_vm._v("Add Another Product Price")])],1),_c('BaseButton',{attrs:{"buttonColor":"blueGreen","textColor":"white","loading":_vm.buttonLoading,"invalid":_vm.isUpdated(invalid, changed)},nativeOn:{"click":function($event){return _vm.submit($event)}}},[_vm._v("Save")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }