<template>
  <div>
    <v-img src="@/assets/greenTick.svg" class="mx-auto" :width="width || 120" />
    <h2 class="text-center midnightGreen--text">
      <strong>Success!</strong>
    </h2>
    <p class="text-center text-h6 font-weight-normal">
      <slot />
    </p>
  </div>
</template>

<script>
export default {
  name: "SuccessIcon",
  props: {
    width: String
  }
};
</script>
