<template>
  <v-icon v-if="isOwner" color="#F8B234" class="icon--owner"
    >mdi-account-star</v-icon
  >
</template>

<script>
export default {
  name: "ItemOwner",
  props: {
    isOwner: Boolean
  }
};
</script>
