import Cookie from "js-cookie";

import { jukuaApi } from "./jukua";

export const getCookie = async () => {
  let token = Cookie.get("XSRF-TOKEN");

  if (token) {
    return token;
  }

  return await jukuaApi({
    url: "/sanctum/csrf-cookie",
    baseURL: process.env.VUE_APP_API_URL.replace("/api", "")
  });
};
