<template>
  <v-list-item-group v-if="isPopulated">
    <div class="pt-10 d-flex flex-column align-center">
      <v-icon v-if="items" color="#FF5666" class="ma-2" size="100"
        >domain_disabled</v-icon
      >
      <v-icon v-else-if="noInterests" color="#FF5666" class="ma-2" size="120"
        >supervised_user_circle</v-icon
      >
      <v-img v-else class="pa-5" contain src="@/assets/Framebell.svg" />
      <div :class="`${parseTextColor} font-weight-bold ${parseTextSize} pt-5`">
        <slot />
      </div>
    </div>
  </v-list-item-group>
</template>

<script>
export default {
  name: "NoItems",
  props: {
    isPopulated: Boolean,
    items: Boolean,
    noInterests: Boolean,
    textColor: String,
    textSize: String
  },
  computed: {
    parseTextColor() {
      return this.textColor ?? "midnightGreen--text";
    },
    parseTextSize() {
      return this.textSize ?? "text-h5";
    }
  }
};
</script>
