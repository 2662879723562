import swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

import router from "@/router";
import { jukuaApi } from "@/api/jukua";
import { ghostApi } from "@/api/ghost";
import { getCookie } from "@/api/csrf";
import { timeElapsed } from "@/utils/timeElapsed";
import { extractErrorResponse } from "@/utils/errorResponse";
import * as mutations from "./mutation-types";
import * as action from "./dispatch-types";

export const actions = {
  /**
   *
   * Authorization routes
   *
   */

  registration: async (
    { commit, dispatch },
    { name, email, id_passport_no, password }
  ) => {
    try {
      commit(mutations.setIsLoading);
      await getCookie();
      await jukuaApi.post("/auth/register", {
        name,
        email,
        password,
        id_passport_no,
        url: `${process.env.VUE_APP_FRONT_END_URL}/verify`
      });

      commit(mutations.setLoaded);
      commit(mutations.setRegistered);
      setTimeout(() => {
        router.push("/login");
        commit(mutations.setRegistered);
      }, 5000);
    } catch (error) {
      dispatch(action.error, error);
    }
  },
  verifyEmail: async ({ commit, dispatch, state }) => {
    try {
      await jukuaApi.post("/auth/verify-email", {
        token: state.verificationToken
      });
      commit(
        mutations.setAlert,
        "You have successfully registered with Jukua!"
      );
      dispatch(action.fireAlert, { alert: state.alerts });
      router.push("/login");
    } catch (error) {
      dispatch(action.error, error);
    }
  },
  login: async ({ commit, dispatch }, user) => {
    try {
      commit(mutations.setIsLoading);
      await getCookie();
      const response = await jukuaApi.post("/auth/login", {
        ...user
      });
      const token = response.data.data.access_token;
      commit(mutations.setLoaded);
      commit(mutations.setToken, token);

      router.push("/dashboard");
    } catch (error) {
      dispatch(action.error, error);
    }
  },
  logout: ({ commit }) => {
    commit(mutations.logout);
  },
  forgotPassword: async ({ commit, dispatch }, data) => {
    try {
      await jukuaApi.post("/auth/forgot-password", data);
      commit(mutations.setIsSubmitted);
      setTimeout(() => {
        router.push("/login");
        commit(mutations.setIsSubmitted);
      }, 5000);
    } catch (error) {
      dispatch(action.error, error);
    }
  },
  resetPassword: async ({ commit, dispatch, state }, password) => {
    try {
      await jukuaApi.post("/auth/reset-password", {
        password,
        token: state.verificationToken
      });
      commit(mutations.setAlert, "Password changed successfully");
      dispatch(action.fireAlert, { alert: state.alerts });
      router.push("/login");
    } catch (error) {
      dispatch(action.error, error);
    }
  },
  fetchTokenFromUrl: ({ commit }) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    commit(mutations.setVerificationToken, urlParams.get("token"));
  },
  changePassword: async ({ commit, dispatch, state }, data) => {
    try {
      await jukuaApi.post("/auth/change-password", data);
      commit(
        mutations.setAlert,
        "Password changed successfully, you will be logged out!"
      );
      dispatch(action.fireAlert, { alert: state.alerts });
      dispatch(action.logout);
      router.push("/login");
    } catch (error) {
      dispatch(action.error, error);
    }
  },

  /**
   *
   * Profile routes
   *
   */

  fetchProfile: async ({ commit, dispatch }) => {
    try {
      const response = await jukuaApi.get("/profile");
      commit(mutations.setProfile, response.data.data);
    } catch (error) {
      dispatch(action.error, error);
    }
  },
  updateProfile: async ({ commit, dispatch, state }, data) => {
    try {
      if (data.email) {
        data = { ...data, url: `${process.env.VUE_APP_FRONT_END_URL}/verify` };
      }

      const response = await jukuaApi.patch("/profile", data);
      commit(mutations.setAlert, response.data.message);
      dispatch(action.fireAlert, { alert: state.alerts });

      if (data.email) {
        dispatch(action.logout);
        router.push("/login");
        return;
      }

      dispatch(action.fetchProfile);
    } catch (error) {
      dispatch(action.error, error);
    }
  },
  deleteProfile: async ({ commit, dispatch, state }, password) => {
    try {
      await jukuaApi.delete("/profile", {
        data: password
      });
      commit(mutations.setAlert, "We are sad to see you go!");
      dispatch(action.fireAlert, { alert: state.alerts });
      dispatch(action.logout);
      router.push("/login");
    } catch (error) {
      dispatch(action.error, error);
    }
  },

  /**
   *
   * Company routes
   *
   */

  createCompany: async ({ commit, dispatch, state, getters }) => {
    try {
      commit(mutations.setIsLoading);
      commit(mutations.setCreateCompanyForm, state.companyForm);

      const {
        name,
        email,
        type,
        phone_number,
        address,
        physical_location,
        kra_pin,
        business_regno
      } = getters.getCompanyForm;

      const response = await jukuaApi.post("/companies", {
        name,
        email,
        type,
        phone_number,
        address,
        physical_location,
        kra_pin,
        business_regno
      });

      const companyId = response.data.data.company_id;

      commit(mutations.setCompanyRegistrationForm, {});
      commit(mutations.setLoaded);
      commit(
        mutations.setAlert,
        "You have successfully created a company! Our team will need 24hrs to verify the details you have provided to us."
      );

      dispatch(action.fireAlert, { alert: state.alerts });

      router.push({
        path: "/company-logo",
        query: { company: companyId }
      });
    } catch (error) {
      dispatch(action.error, error);
    }
  },
  updateCompany: async ({ commit, dispatch, state }, { id, formItems }) => {
    try {
      await jukuaApi.patch(`/companies/${id}`, { ...formItems });
      commit(mutations.setAlert, "Company updated successfully!");
      dispatch(action.fireAlert, { alert: state.alerts });
      dispatch(action.fetchCompanyById, id);
    } catch (error) {
      commit(mutations.setAlert, "Update failed");
      dispatch(action.fireAlert, {
        alert: state.alerts,
        icon: action.error
      });
    }
  },
  deleteCompany: async (_, companyId) => {
    await jukuaApi.delete(`/companies/${companyId}`);
  },
  fetchCompanies: async (
    { commit, dispatch },
    { page: page = 1 } = { page: 1 }
  ) => {
    try {
      const response = await jukuaApi.get(`/companies?page=${page}`);

      const pages = {
        companies: {
          currentPage: response.data.data.current_page,
          lastPage: response.data.data.last_page
        }
      };

      commit(mutations.setPages, pages);
      commit(mutations.setCompanies, response.data.data.data);
    } catch (error) {
      dispatch(action.error, error);
    }
  },
  fetchMyCompanies: async ({ commit, dispatch }) => {
    try {
      const response = await jukuaApi.get(`/companies-user`);
      commit(mutations.setMyCompanies, response.data.data.data);
    } catch (error) {
      dispatch(action.error, error);
    }
  },
  fetchCompanyById: async ({ commit, dispatch }, id) => {
    try {
      const response = await jukuaApi.get(`/companies/${id}`);

      commit(mutations.setCompany, response.data.data);
    } catch (error) {
      dispatch(action.error, error);
    }
  },

  /**
   *
   * Product routes
   *
   */

  addProduct: async ({ commit, dispatch }, { companyId, data }) => {
    try {
      commit(mutations.setButtonLoading);

      const { notify_threshold } = data;
      notify_threshold ? data : delete data.notify_threshold;

      const response = await jukuaApi.post(
        `/company/${companyId}/product`,
        data
      );

      const productId = response.data.data.product_id;

      commit(mutations.setButtonLoaded);
      router.push(`/products/${companyId}/pricing/${productId}`);
    } catch (error) {
      dispatch(action.error, error);
    }
  },
  updateProduct: async ({ commit, dispatch, state }, { productId, data }) => {
    try {
      await jukuaApi.patch(`/products/${productId}`, data);
      commit(mutations.setAlert, "Product updated successfully!");
      dispatch(action.fireAlert, { alert: state.alerts });
      dispatch(action.fetchProductById, productId);
    } catch (error) {
      dispatch(action.error, error);
    }
  },
  deleteProduct: async (_, productId) => {
    await jukuaApi.delete(`/products/${productId}`);
  },
  fetchProducts: async (
    { commit, dispatch },
    { page: page = 1 } = { page: 1 }
  ) => {
    try {
      const response = await jukuaApi.get(`/products?page=${page}`);

      const data = response.data.data.data.map(product => product.category);

      const filteredCategories = [...new Set(data)];
      const categories = filteredCategories.map(category => ({
        name: category
      }));

      categories.unshift({ name: "All" });

      const pages = {
        products: {
          currentPage: response.data.data.current_page,
          lastPage: response.data.data.last_page
        }
      };

      commit(mutations.setCategories, categories);
      commit(mutations.setPages, pages);
      commit(mutations.setProducts, response.data.data.data);
    } catch (error) {
      dispatch(action.error, error);
    }
  },
  fetchProductById: async ({ commit, dispatch }, id) => {
    try {
      const response = await jukuaApi.get(`/products/${id}`);

      commit(mutations.setProduct, response.data.data);
    } catch (error) {
      dispatch(action.error, error);
    }
  },
  fetchProductImagesById: async ({ commit, dispatch }, id) => {
    try {
      const response = await jukuaApi.get(`/products/${id}/images`);

      commit(mutations.setProductImages, response.data.data.data);
    } catch (error) {
      dispatch(action.error, error);
    }
  },
  fetchProductsByCompany: async ({ commit, dispatch }, companyId) => {
    try {
      const response = await jukuaApi.get(`/company/${companyId}/products`);

      commit(mutations.setProduct, response.data.data);
      commit(mutations.setCompanyProducts, response.data.data.data);
    } catch (error) {
      dispatch(action.error, error);
    }
  },

  /**
   *
   * Pricing routes
   *
   */

  addPricing: async (
    { state, dispatch, commit },
    { productId, companyId, currentRoute, data }
  ) => {
    try {
      commit(mutations.setButtonLoading);

      await jukuaApi.post(`/products/${productId}/pricing`, data);

      if (Object.keys(state.redirectRoute).length) {
        commit(mutations.setButtonLoaded);
        return dispatch(action.redirect, currentRoute);
      }

      commit(mutations.setButtonLoaded);
      router.push(`/products/${productId}/upload?company=${companyId}`);
    } catch (error) {
      dispatch(action.error, error);
    }
  },
  updatePricing: async (
    { commit, dispatch, state },
    { pricingId, productId, companyId, currentRoute, data }
  ) => {
    try {
      commit(mutations.setIsLoading);
      await jukuaApi.patch(`/pricing/${pricingId}`, data);
      commit(mutations.setAlert, "Updated Successfully!");
      dispatch(action.fireAlert, { alert: state.alerts });
      commit(mutations.setLoaded);

      if (Object.keys(state.redirectRoute).length) {
        return dispatch(action.redirect, currentRoute);
      }

      router.push(`/product/${productId}?company=${companyId}`);
    } catch (error) {
      dispatch(action.error, error);
    }
  },
  deletePricing: async (_, pricingId) => {
    await jukuaApi.delete(`/pricing/${pricingId}`, {
      data: {
        pricingId
      }
    });
  },
  fetchPricingById: async ({ commit, dispatch }, pricingId) => {
    try {
      const response = await jukuaApi.get(`/pricing/${pricingId}`);
      commit(mutations.setPricing, response.data.data);
    } catch (error) {
      dispatch(action.error, error);
    }
  },
  fetchPricingByProductId: async ({ commit, dispatch }, productId) => {
    try {
      const response = await jukuaApi.get(`/products/${productId}/pricing`);
      commit(mutations.setPricings, response.data.data.data);
      commit(mutations.setButtonLoaded);
    } catch (error) {
      dispatch(action.error, error);
    }
  },

  /**
   *
   * Interests routes
   *
   */

  expressInterest: async (
    { commit, dispatch, state },
    { pricingId, companyId }
  ) => {
    try {
      if (state.myCompanies.length) {
        await jukuaApi.post(`/company/${companyId}/interest/${pricingId}`);
        commit(mutations.setIsInterested);
      } else {
        commit(
          mutations.setAlert,
          "You don't have a company yet. Register one with us to continue."
        );
        dispatch(action.fireAlert, {
          alert: state.alerts,
          icon: "error"
        });
      }
    } catch (error) {
      dispatch(action.error, error);
    }
  },
  acceptInterest: async (
    { commit, dispatch, state },
    { interestId, productId }
  ) => {
    try {
      await jukuaApi.post(`/accept-interest/${interestId}`);
      commit(mutations.setAlert, "You have accepted this request!");
      dispatch(action.fireAlert, { alert: state.alerts });
      dispatch(action.fetchInterestsByProductId, productId);
    } catch (error) {
      dispatch(action.error, error);
    }
  },
  declineInterest: async (
    { commit, dispatch, state },
    { interestId, productId }
  ) => {
    try {
      await jukuaApi.post(`/decline-interest/${interestId}`);
      commit(mutations.setAlert, "You have declined this request!");
      dispatch(action.fireAlert, { alert: state.alerts });
      dispatch(action.fetchInterestsByProductId, productId);
    } catch (error) {
      dispatch(action.error, error);
    }
  },
  fetchInterests: async ({ commit, dispatch }) => {
    try {
      const response = await jukuaApi.get("/interests");
      commit(mutations.setInterests, response.data.data.data);
    } catch (error) {
      dispatch(action.error, error);
    }
  },
  fetchInterestsByProductId: async ({ commit, dispatch }, productId) => {
    try {
      const response = await jukuaApi.get(`/products/${productId}/interests`);
      commit(mutations.setProductInterests, response.data.data.data);
    } catch (error) {
      dispatch(action.error, error);
    }
  },

  /**
   *
   * Upload routes
   *
   */

  onFileSelected: ({ commit }, event) => {
    const file = event.target.files[0];
    commit(mutations.setFileToUpload, file);
    const url = URL.createObjectURL(file);
    commit(mutations.setPreviewImage, url);
  },
  uploadFiles: async (
    { commit, dispatch, state },
    { id, category, skipAlert = false, next = false }
  ) => {
    try {
      const formData = new FormData();
      formData.append("file", state.fileToUpload);
      formData.append("category", category);
      formData.append("category_id", id);

      const response = await jukuaApi.post("/uploads", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });

      if (!skipAlert) {
        commit(mutations.setAlert, response.data.message);
        await dispatch(action.fireAlert, { alert: state.alerts });
      }

      if (next) {
        commit(mutations.setNext);
        commit(mutations.setPreviewImage);
      } else {
        commit(mutations.setPreviewImage);
      }
    } catch (error) {
      dispatch(action.error, error);
    }
  },

  /**
   *
   * Training routes
   *
   */

  formatTrainingData: async (_, posts) => {
    const formattedData = await posts.map(post => {
      let formattedDate = new Date(post.published_at);
      post.published_at = formattedDate.toDateString();
      let postSnippet = post.excerpt.substring(0, 140);
      post.excerpt = `${postSnippet}...  Read more`;
      if (!post.feature_image) {
        post.feature_image =
          "https://images.freeimages.com/images/large-previews/864/mammatus-cloud-formation-1640460.jpg";
      }
      return post;
    });

    return formattedData;
  },
  filterByTag: async ({ commit, dispatch }, event) => {
    commit(mutations.setFilteredTrainingChangedToFalse);
    const tag = event.currentTarget.name.replace(/\s+/g, "-");
    const response = await ghostApi.get("/posts", {
      params: {
        filter: `tag:${tag}`,
        limit: 20
      }
    });

    commit(mutations.setFilteredTrainingChanged);

    const filteredTaining = await dispatch(
      action.formatTrainingData,
      response.data.posts
    );

    commit(mutations.setFilteredTraining, filteredTaining);
  },
  fetchTags: async ({ commit }) => {
    const response = await ghostApi.get("/tags");

    const tags = response.data.tags;
    commit(mutations.setTags, tags);
  },
  filterByPreferences: async ({ commit, dispatch, state }) => {
    if (state.token) {
      commit(mutations.emptyPreferenceTags, true);
      const response = await jukuaApi.get(`/tags`);

      if (response.data.data?.length) {
        commit(mutations.emptyPreferenceTags, false);
      }

      commit(mutations.setPreferenceTags, response.data.data);

      const tags = response.data.data;

      let filter;

      if (tags?.length) {
        filter = `tag:[${tags}]`;
      }

      const { data } = await ghostApi.get("/posts", {
        params: {
          filter,
          limit: 20
        }
      });

      const filteredTaining = await dispatch(
        action.formatTrainingData,
        data.posts
      );

      commit(mutations.setFilteredPreferences, filteredTaining);
    }
  },
  fetchTrainingContent: async (
    { commit, dispatch },
    { page: page = 1, records: records = 15 } = { page: 1, records: 15 }
  ) => {
    const response = await ghostApi.get("/posts", {
      params: {
        limit: records,
        page
      }
    });

    const pages = {
      training: {
        currentPage: response.data.meta.pagination.page,
        lastPage: response.data.meta.pagination.pages
      }
    };

    commit(mutations.setFilteredTrainingChangedToFalse);
    commit(mutations.setPages, pages);
    const trainingContent = await dispatch(
      action.formatTrainingData,
      response.data.posts
    );

    commit(mutations.setTrainingContent, trainingContent);
  },
  fetchTrainingContentById: async ({ commit }, id) => {
    let response = await ghostApi.get("/posts");
    let [post] = response.data.posts.filter(post => post.id === id);

    while (!post && !!response.data.meta.pagination.next) {
      response = await ghostApi.get("/posts", {
        page: response.data.meta.pagination.next
      });
      [post] = response.data.posts.filter(post => post.id === id);
    }

    const { title, html } = post;
    commit(mutations.setTrainingContentById, { title, html });
  },
  selectedCourses: async ({ dispatch, commit }, preferences) => {
    try {
      commit(mutations.setButtonLoading);

      await jukuaApi.post("/tags", {
        tags: preferences
      });

      commit(mutations.setButtonLoaded);

      location.reload();
    } catch (error) {
      dispatch(action.error, error);
    }
  },

  /**
   *
   * Notification routes
   *
   */

  fetchNotifications: async ({ commit, dispatch }) => {
    try {
      const response = await jukuaApi.get("/notifications");
      const notificationResponse = response.data.data.data;

      const notifications = [];

      if (notificationResponse.length) {
        notificationResponse.forEach(notification => {
          const notif = {
            user_id: notification.data.user_id,
            interestId: notification.data.interest_id,
            companyId: notification.data.company_id,
            productId: notification.data.product_id,
            notificationId: notification.id,
            productName: notification.data.product_name,
            companyName: notification.data.company_name,
            status: notification.data.status,
            unit: notification.data.unit,
            unitPrice: notification.data.min_price,
            minUnits: notification.data.min_unit,
            readAt: notification.read_at,
            elapsedTime: timeElapsed(notification.updated_at),
            image: notification.data.url
          };

          notifications.push(notif);
        });
      }

      commit(mutations.setNotifications, notifications);
    } catch (error) {
      dispatch(action.error, error);
    }
  },
  notificationRead: async ({ dispatch }, notificationId) => {
    try {
      await jukuaApi.patch(`/notifications/${notificationId}`);
    } catch (error) {
      dispatch(action.error, error);
    }
  },

  /**
   *
   * Alerts routes
   *
   */
  fireAlert: async (
    { commit },
    { alert = "Something went wrong!", icon = "success" }
  ) => {
    commit(mutations.setAlert, null);
    await swal.fire({
      text: alert,
      icon
    });
  },
  confirmationAlert: async (
    { commit, dispatch, state },
    {
      companyId,
      productId,
      pricingId,
      sameRoute,
      currentRoute,
      propertyToDelete
    }
  ) => {
    commit(mutations.setAlert, null);
    const result = await swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#388E3C",
      cancelButtonColor: "#D50000",
      confirmButtonText: "Yes, delete it!"
    });

    if (result.value) {
      switch (propertyToDelete) {
        case "product":
          dispatch(action.deleteProduct, productId);
          await swal.fire(
            "Deleted!",
            `Your product has been deleted`,
            "success"
          );
          router.push(`/company/${companyId}`);
          break;
        case "company":
          dispatch(action.deleteCompany, companyId);
          await swal.fire(
            "Deleted!",
            `Company profile has been deleted`,
            "success"
          );
          router.push("/profile");
          break;
        case "pricing":
          commit(mutations.setButtonLoading);
          dispatch(action.deletePricing, pricingId);
          await swal.fire(
            "Deleted!",
            `Product price point has been deleted`,
            "success"
          );

          if (sameRoute) {
            return dispatch(action.fetchPricingByProductId, productId);
          }

          if (Object.keys(state.redirectRoute).length) {
            commit(mutations.setButtonLoaded);
            return dispatch(action.redirect, currentRoute);
          }
      }
    }
  },
  error: async ({ commit, dispatch, state }, error) => {
    const message = extractErrorResponse(error);

    commit(mutations.setAlert, message);
    commit(mutations.setLoaded);
    commit(mutations.setButtonLoaded);
    dispatch(action.fireAlert, {
      alert: state.alerts,
      icon: "error"
    });
  },

  /**
   *
   * Search routes
   *
   */

  searchProducts: async ({ commit, dispatch }, { query, page = 1 }) => {
    try {
      const response = await jukuaApi.get(
        `/products?search=${query}&page=${page}`
      );
      const data = response.data.data.data.map(product => product.category);
      const filteredCategories = [...new Set(data)];
      const categories = filteredCategories.map(category => ({
        name: category
      }));

      categories.unshift({ name: "All" });

      const pages = {
        searchedProducts: {
          currentPage: response.data.data.current_page,
          lastPage: response.data.data.last_page
        }
      };

      commit(mutations.setSearchedCategories, categories);
      commit(mutations.setPages, pages);
      commit(mutations.setSearchedProducts, response.data.data.data);
    } catch (error) {
      dispatch(action.error, error);
    }
  },
  searchCompanies: async ({ commit, dispatch }, { query, page = 1 }) => {
    try {
      const response = await jukuaApi.get(
        `/companies?search=${query}&page=${page}`
      );
      const pages = {
        searchedCompanies: {
          currentPage: response.data.data.current_page,
          lastPage: response.data.data.last_page
        }
      };

      commit(mutations.setPages, pages);
      commit(mutations.setSearchedCompanies, response.data.data.data);
    } catch (error) {
      dispatch(action.error, error);
    }
  },

  /**
   *
   * Redirect Utility
   *
   */
  redirect: async ({ state, commit }, currentRoute) => {
    if (Object.keys(state.redirectRoute).length) {
      const redirectToRoute = state.redirectRoute[currentRoute];
      commit(mutations.filterRedirectRoute, redirectToRoute);
      return router.push(redirectToRoute);
    }
  }
};
