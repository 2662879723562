<template>
  <v-card v-if="product" flat>
    <v-card flat class="px-6 pt-6">
      <v-card tile flat class="rounded-tr-xl container--relative elevation-3">
        <v-img v-if="product.url" height="30vh" :src="product.url" />
        <v-img v-else height="30vh" src="@/assets/no-company-logo.jpg" />
      </v-card>
      <v-card tile flat class="d-flex align-center justify-space-between">
        <v-card-title class="midnightGreen--text px-0 text-h6">{{
          product.name
        }}</v-card-title>
        <v-chip
          small
          class="text-uppercase"
          color="secondaryNeutral"
          text-color="midnightGreen"
          label
          >{{ product.location }}</v-chip
        >
      </v-card>
    </v-card>
  </v-card>
</template>

<script>
export default {
  name: "ProductImage",
  props: {
    product: Object,
    editable: Boolean
  }
};
</script>
