var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BottomCard',{attrs:{"icon":_vm.confirmDelete ? 'error' : '',"iconColor":"pink"},on:{"toggledSheet":_vm.sheetClosed},scopedSlots:_vm._u([{key:"button",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_vm._t("default",null,{"attrs":attrs,"on":on})]}},{key:"content",fn:function(){return [_c('v-card-title',{staticClass:"text-h5 midnightGreen--text font-weight-bold mb-2"},[_vm._v("Delete Profile")]),(!_vm.confirmDelete)?_c('v-card-subtitle',{staticClass:"text-subtitle-1 midnightGreen--text"},[_vm._v("Are you sure you want to delete your Jukua account?")]):_c('v-card-subtitle',{staticClass:"text-subtitle-1 midnightGreen--text"},[_vm._v("You will permanently lose your account data. There is no turning back after this!")]),(!_vm.confirmDelete)?_c('v-btn',{staticClass:"font-weight-bold py-8 text-h6 text-capitalize midnightGreen--text",attrs:{"large":"","width":"100%","color":"primary","dark":""},on:{"click":function($event){_vm.confirmDelete = !_vm.confirmDelete}}},[_vm._v(" Continue ")]):_vm._e(),_c('v-card-text',[_c('ValidationObserver',{ref:"obs",staticClass:"flex d-flex flex-column justify-space-between",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',[(_vm.confirmDelete)?_c('v-col',{staticClass:"my-4",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required|min:5|max:36","name":"Password","vid":"confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"my-1",attrs:{"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password',"label":"Password","error-messages":errors,"required":""},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|confirmed:confirm","name":"Password confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"my-1",attrs:{"label":"Confirm Password","type":"password","error-messages":errors,"required":""},model:{value:(_vm.passwordConfirmed),callback:function ($$v) {_vm.passwordConfirmed=$$v},expression:"passwordConfirmed"}})]}}],null,true)}),_c('v-btn',{staticClass:"font-weight-bold py-8 text-h6 text-capitalize white--text",attrs:{"large":"","width":"100%","color":"pink","disabled":invalid},on:{"click":function($event){return _vm.deleteProfile({ password: _vm.password })}}},[_vm._v(" Confirm Deletion ")])],1):_vm._e()],1)]}}])})],1)]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }