<template>
  <nav>
    <v-navigation-drawer
      v-if="$vuetify.breakpoint.mobile"
      v-model="drawer"
      width="350"
      hide-overlay
      app
    >
      <v-list>
        <v-row no-gutters class="mx-4 my-3 mb-10">
          <v-col align="left">
            <v-icon color="midnightGreen" @click="drawer = !drawer"
              >mdi-close</v-icon
            >
          </v-col>
        </v-row>
        <v-list-item
          v-for="item in linksToDisplay"
          :key="item.title"
          :to="item.path"
          class="mt-5"
        >
          <v-list-item-content
            class="midnightGreen--text text-h6 font-weight-bold"
            >{{ item.title }}</v-list-item-content
          >
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-toolbar
      v-if="$vuetify.breakpoint.mobile"
      flat
      color="transparent"
      class="d-flex justify-end"
    >
      <span class="header--nav">
        <v-app-bar-nav-icon @click="drawer = !drawer"> </v-app-bar-nav-icon>
      </span>
    </v-toolbar>
    <v-toolbar v-else flat color="transparent" class="d-flex justify-end">
      <v-toolbar-items
        v-for="item in linksToDisplay"
        :key="item.index"
        class="mt-5"
      >
        <v-btn
          text
          :to="item.path"
          :color="item.color"
          :class="`mx-1 ${item.class} text-capitalize font-weight-bold text-h6`"
        >
          {{ item.title }}
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MobileNavbar",
  data: () => ({
    drawer: false,
    loggedOutLinks: [
      {
        title: "Home",
        path: "/",
        color: "midnightGreen"
      },
      {
        title: "Dashboard",
        path: "/dashboard",
        color: "midnightGreen"
      },
      {
        title: "FAQs",
        path: "/faqs",
        color: "midnightGreen"
      },
      {
        title: "Sign Up",
        path: "/register",
        color: "midnightGreen",
        class: "yellow darken-3 rounded-lg px-8"
      },
      {
        title: "Sign In",
        path: "/login",
        color: "midnightGreen"
      },
      {
        title: "Training",
        path: "/training",
        color: "midnightGreen"
      }
    ],
    loggedInLinks: [
      {
        title: "Home",
        path: "/",
        color: "midnightGreen"
      },
      {
        title: "Dashboard",
        path: "/dashboard",
        color: "midnightGreen"
      },
      {
        title: "FAQs",
        path: "/faqs",
        color: "midnightGreen"
      },
      {
        title: "Profile",
        path: "/profile",
        color: "midnightGreen"
      },
      {
        title: "Notifications",
        path: "/notifications",
        color: "midnightGreen"
      },
      {
        title: "Interests",
        path: "/interests",
        color: "midnightGreen"
      },
      {
        title: "Training",
        path: "/training",
        color: "midnightGreen"
      },
      {
        title: "Logout",
        path: "/logout",
        color: "midnightGreen"
      }
    ]
  }),
  computed: {
    ...mapGetters(["isLoggedIn"]),
    linksToDisplay() {
      return this.isLoggedIn ? this.loggedInLinks : this.loggedOutLinks;
    }
  }
};
</script>

<style scoped>
.header--nav {
  position: absolute;
  right: 2%;
}

.v-application .v-list-item--active {
  text-decoration: underline;
  text-decoration-color: #ff5666;
  text-decoration-thickness: 4px !important;
  background: white !important;
}
</style>
