<template>
  <v-bottom-sheet
    v-model="sheet"
    inset
    scrollable
    :width="alertWidth"
    @click:outside="toggleSheet()"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="button" :attrs="attrs" :on="on" />
    </template>
    <v-card
      class="rounded-tr-xl pa-8 sheet"
      elevation="24"
      width="100%"
      :height="alertHeight"
    >
      <v-btn class="ma-5 icon--close" text icon @click="toggleSheet">
        <v-icon text large depressed color="midnightGreen">clear</v-icon>
      </v-btn>
      <div>
        <v-icon class="ma-2" size="70" :color="parseIconColor">{{
          parseIcon
        }}</v-icon>
      </div>
      <slot name="content" />
    </v-card>
  </v-bottom-sheet>
</template>

<script>
export default {
  name: "BottomCard",
  props: {
    icon: String,
    iconColor: String
  },
  data() {
    return {
      sheet: false
    };
  },
  computed: {
    alertWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "sm":
        case "xs":
          return "100%";
        default:
          return "39%";
      }
    },
    alertHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "sm":
        case "xs":
          return "20%";
        default:
          return "10%";
      }
    },
    parseIcon() {
      if (this.icon) {
        return this.icon;
      }

      return "help";
    },
    parseIconColor() {
      if (this.iconColor) {
        return this.iconColor;
      }

      return "blueGreen";
    }
  },
  methods: {
    toggleSheet() {
      this.sheet = false;
      this.$emit("toggledSheet", this.sheet);
    }
  }
};
</script>

<style scoped>
.sheet {
  position: relative;
}

.icon--close {
  position: absolute;
  right: 0;
  top: 0;
}
</style>
