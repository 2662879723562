export const getters = {
  isLoggedIn: state => !!state.token,
  isLoading: state => state.isLoading,
  isNext: state => state.next,
  isEmpty: state => state.isEmpty,
  getInterests: state => state.interests,
  getProductInterests: state => state.productInterests,
  getPricings: state => state.pricings,
  getTrainingContent: state => state.trainingContent,
  getPreviewImage: state => state.previewImage,
  getTrainingContentById: state => state.trainingContentById,
  getIsSubmitted: state => state.isSubmitted,
  getCompanyForm: state => state.companyForm
};
