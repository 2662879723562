<template>
  <v-container class="text-center container" fill-height>
    <CenteredLogo />
    <v-row align="center">
      <v-col>
        <h1 class="display-2 text--white">Whoops, 404</h1>

        <p class="text--white">The page you were looking for does not exist</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CenteredLogo from "./CenteredLogo";

export default {
  name: "NotFound",
  components: {
    CenteredLogo
  }
};
</script>

<style scoped>
.container {
  height: calc(100vh - 58px);
}

.logo-container {
  background-color: transparent;
  border: none;
}

img {
  width: 40vh;
}

.text--white {
  color: white;
}
</style>
