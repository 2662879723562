<template>
  <Card displayControls title="Interests">
    <v-tabs
      class="flex-grow-0"
      v-model="selectedCategory"
      color="pink"
      slider-size="4"
      grow
      show-arrows
    >
      <v-tab
        v-for="category in categories"
        :key="category.id"
        class="blueGreen--text text-capitalize font-weight-bold text-h6"
      >
        {{ category.status }}
      </v-tab>
    </v-tabs>
    <NoItems
      v-if="!filteredInterests.length"
      :isPopulated="!filteredInterests.length"
      textColor="blueGreen--text"
      noInterests
    >
      No Interests
    </NoItems>
    <v-list three-line nav>
      <v-list-item-group
        v-for="interest in filteredInterests"
        :key="interest.id"
      >
        <v-list-item :ripple="false" inactive>
          <v-list-item-avatar
            tile
            size="80"
            class="rounded-tr-xl grey lighten-4 elevation-1"
          >
            <v-img v-if="interest.url" :src="interest.url"></v-img>
            <v-icon color="midnightGreen" size="60" v-else
              >account_balance</v-icon
            >
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              >{{ interest.name | capitalize }}
              <br />
              {{ interest.unit_price }} per
              {{ interest.unit | lowercase }}
              <br />
              {{ interest.delivery_cost }}</v-list-item-title
            >
            <v-list-item-title class="mb-1 h6 font-weight-bold">
              {{ interest.company_name | capitalize }}
              <br />
              {{ interest.company_type }}
              <br />
            </v-list-item-title>
          </v-list-item-content>
          <v-chip
            class="ma-2"
            :color="statusColor(interest)"
            label
            text-color="white"
          >
            {{ interest.status }}
          </v-chip>
        </v-list-item>
        <v-divider inset></v-divider>
      </v-list-item-group>
    </v-list>
  </Card>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";

import Card from "@/components/Card";
import NoItems from "@/components/NoItems";

export default {
  name: "Interests",
  components: {
    Card,
    NoItems
  },
  data() {
    return {
      selectedCategory: 0,
      categories: [
        { id: 0, status: "All" },
        { id: 1, status: "accepted" },
        { id: 2, status: "declined" },
        { id: 3, status: "notified" }
      ]
    };
  },
  async created() {
    await this.setIsLoading();
    await this.fetchInterests();
    await this.setLoaded();
  },
  computed: {
    ...mapGetters(["getInterests"]),
    ...mapState(["interests"]),
    filteredInterests() {
      let status = this.categories[this.selectedCategory].status;

      if (status === "All") {
        return this.getInterests;
      } else {
        return this.getInterests.filter(item => item.status === status);
      }
    }
  },
  methods: {
    ...mapActions(["fetchInterests"]),
    ...mapMutations(["setIsLoading", "setLoaded"]),
    statusColor({ status }) {
      switch (status) {
        case "notified":
          return "#35A19E";
        case "accepted":
          return "green";
        default:
          return "red";
      }
    }
  }
};
</script>

<style scoped>
.v-list-item__title {
  white-space: normal;
}
</style>
