<template>
  <v-img
    width="15vh"
    v-if="!hide"
    class="control--height"
    src="@/assets/logo beta white.svg"
  />
</template>

<script>
export default {
  name: "Logo",
  props: {
    hide: Boolean
  }
};
</script>

<style scoped>
.control--height {
  position: absolute;
  bottom: 90%;
}

@media only screen and (max-width: 441px) {
  .control--height {
    bottom: 92.5%;
  }
}
</style>
