export const extractErrorResponse = error => {
  if (!error.response) return "Network error!";

  if (error.response.data.errors) {
    const invalidField = Object.keys(error.response.data.errors)[0];
    const errorMessage = error.response.data.errors[invalidField][0];
    const message = filterPeriod(errorMessage);
    return message;
  }

  return filterPeriod(error.response.data.message);
};

const filterPeriod = message => {
  return message.replace(/\W$/, "");
};
