export const checkRedirectRouteMixin = {
  methods: {
    checkRedirectRoute() {
      const redirectToRoute =
        this.$props?.redirectToRoute ?? this.$attrs?.redirectToRoute;

      if (redirectToRoute) {
        this.setRedirectRoute({
          currentUrl: this.$route.fullPath,
          redirectRoute: redirectToRoute
        });
      }
    }
  }
};
