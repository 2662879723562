export const setRegistered = "setRegistered";
export const setToken = "setToken";
export const setVerificationToken = "setVerificationToken";
export const setIsLoading = "setIsLoading";
export const setLoaded = "setLoaded";
export const setAlert = "setAlert";
export const logout = "logout";
export const setIsSubmitted = "setIsSubmitted";
export const setProfile = "setProfile";
export const setCreateCompanyForm = "setCreateCompanyForm";
export const setCompanyRegistrationForm = "setCompanyRegistrationForm";
export const setCompany = "setCompany";
export const setCompanies = "setCompanies";
export const setMyCompanies = "setMyCompanies";
export const setProducts = "setProducts";
export const setCompanyProducts = "setCompanyProducts";
export const setProduct = "setProduct";
export const setProductImagesEmpty = "setProductImagesEmpty";
export const setProductImages = "setProductImages";
export const setPricing = "setPricing";
export const setPricings = "setPricings";
export const setInterests = "setInterests";
export const setProductInterests = "setProductInterests";
export const setIsInterested = "setIsInterested";
export const setValueOfIsInterested = "setValueOfIsInterested";
export const setPreviewImage = "setPreviewImage";
export const setFileToUpload = "setFileToUpload";
export const setTrainingContent = "setTrainingContent";
export const setTrainingContentById = "setTrainingContentById";
export const setFilteredTraining = "setFilteredTraining";
export const setFilteredPreferences = "setFilteredPreferences";
export const setFilteredTrainingChanged = "setFilteredTrainingChanged";
export const setFilteredTrainingChangedToFalse =
  "setFilteredTrainingChangedToFalse";
export const setTags = "setTags";
export const setPreferenceTags = "setPreferenceTags";
export const emptyPreferenceTags = "emptyPreferenceTags";
export const setNext = "setNext";
export const setNextToFalse = "setNextToFalse";
export const setNotifications = "setNotifications";
export const setCategories = "setCategories";
export const setPages = "setPages";
export const setSearchedProducts = "setSearchedProducts";
export const setSearchedCategories = "setSearchedCategories";
export const setSearchedCompanies = "setSearchedCompanies";
export const setMySearchedCompanies = "setMySearchedCompanies";
export const setRedirectRoute = "setRedirectRoute";
export const resetRedirectRoutes = "resetRedirectRoutes";
export const filterRedirectRoute = "filterRedirectRoute";
export const setButtonLoading = "setButtonLoading";
export const setButtonLoaded = "setButtonLoaded";
