<template>
  <Card displayControls notifications title="Upload Product">
    <ValidationObserver
      class="d-flex flex flex-column justify-content-between"
      ref="observer"
      v-slot="{ invalid }"
    >
      <v-card flat class="px-6 pt-6 flex">
        <h1 class="midnightGreen--text">
          Upload
          <br />Product
        </h1>
        <v-form>
          <ValidationProvider
            name="Product name"
            rules="required|min:3"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model.number="name"
              label="Product Name"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            name="Category"
            rules="required|min:3"
            v-slot="{ errors }"
          >
            <v-select
              v-model="category"
              :items="categories"
              dense
              label="Category"
              :error-messages="errors"
              required
            ></v-select>
          </ValidationProvider>
          <ValidationProvider
            v-if="category === 'Other'"
            name="Other Category"
            rules="required|min:3"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="category_other"
              label="Other category"
              :error-messages="errors"
              type="text"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            name="Unit of measurement"
            mode="eager"
            rules="required|min:2"
            v-slot="{ errors }"
          >
            <v-select
              class="input-field"
              type="text"
              v-model="unit"
              :items="units"
              dense
              :error-messages="errors"
              label="Unit of Measurement"
              required
            ></v-select>
          </ValidationProvider>
          <ValidationProvider
            name="Unit of measurement"
            v-if="unit === 'Other'"
            rules="required|min:2"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="unit_other"
              label="Other unit of measurement"
              :error-messages="errors"
              class="input"
              type="text"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            name="Quantity"
            rules="required|numeric"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model.number="quantity"
              :label="`Quantity ${retrieveUnitType}`"
              :error-messages="errors"
              class="input"
              type="text"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            name="Location of product"
            rules="required|min:4"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="location"
              label="Location of Product"
              :error-messages="errors"
              class="input"
              type="text"
            ></v-text-field>
          </ValidationProvider>
        </v-form>
      </v-card>
      <BaseButton
        @click.native="
          addProduct({
            companyId: $route.params.id,
            data: {
              name,
              category: category_other ? category_other : category,
              unit: unit_other ? unit_other : unit,
              quantity,
              location
            }
          })
        "
        :invalid="invalid"
        :loading="buttonLoading"
        buttonColor="blueGreen"
        textColor="white"
        >Next</BaseButton
      >
    </ValidationObserver>
  </Card>
</template>

<script>
import { mapActions, mapState } from "vuex";

import Card from "@/components/Card";
import BaseButton from "@/components/BaseButton";

export default {
  name: "UploadProduct",
  components: {
    Card,
    BaseButton
  },
  data() {
    return {
      name: "",
      category: "",
      category_other: "",
      unit: "",
      unit_other: "",
      quantity: "",
      location: "",
      categories: [
        "Agriculture",
        "Beauty",
        "Electronics",
        "Fashion",
        "Gaming",
        "Health",
        "Finance",
        "Sporting Goods",
        "Materials",
        "Phones & Tablets",
        "Computing",
        "Other"
      ],
      units: [
        "Tonne",
        "Kg",
        "Grams",
        "Foot",
        "Litres",
        "Crates",
        "Cartons",
        "Dozens",
        "Metre",
        "Hour",
        "Minute",
        "Container",
        "Session",
        "Unit",
        "Other"
      ]
    };
  },
  computed: {
    ...mapState(["buttonLoading"]),
    retrieveUnitType() {
      if (this.unit || this.unit_other) {
        const unitType = this.unit ?? this.unit_other;

        return `(${unitType})`;
      }

      return "";
    }
  },
  methods: mapActions(["addProduct"])
};
</script>
