<template>
  <Card
    classes="px-5 pt-5"
    displayControls
    title="Dashboard"
    notifications
    to="/notifications"
  >
    <v-alert dense border="left" type="warning" v-if="!isLoggedIn">
      <router-link to="/login">
        You are viewing Jukua as a guest.
        <strong>Sign in/Sign up</strong>
      </router-link>
    </v-alert>
    <v-alert
      dense
      border="left"
      type="warning"
      v-if="isLoggedIn && !myCompanies.length"
    >
      You do not have any companies registered yet.
      <router-link to="/register-onboarding">
        <strong>Register Now!</strong>
      </router-link>
    </v-alert>

    <v-card flat v-if="companies.length">
      <v-card flat>
        <v-card-title class="text-h5 midnightGreen--text font-weight-bold"
          >Featured Products</v-card-title
        >
        <v-card-text>
          <v-text-field
            v-model="searchProductQuery"
            @input="isTyping = true"
            label="What do you want to buy?"
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-card-text>
        <v-tabs
          v-if="filteredProducts.length"
          color="pink"
          v-model="selectedCategory"
          slider-size="4"
          grow
          show-arrows
        >
          <v-tab
            class="text-capitalize text-h6 midnightGreen--text"
            v-for="category in preferredCategory"
            :key="category.id"
            >{{ category.name }}</v-tab
          >
        </v-tabs>
        <v-row v-if="filteredProducts.length">
          <v-col
            lg="4"
            md="4"
            sm="12"
            cols="12"
            class="px-2"
            v-for="(product, index) in filteredProducts"
            :key="index"
          >
            <v-card flat>
              <router-link
                :to="toRoute(product.id, product.company_id, filteredProducts)"
              >
                <v-img
                  v-if="product.url"
                  :src="product.url"
                  height="15vh"
                  class="rounded-tr-xl elevation-3 image--rounded"
                >
                  <ItemOwner :isOwner="product.company_owner === 'Y'" />
                </v-img>
                <v-img
                  v-else
                  src="@/assets/company-placeholder.svg"
                  height="15vh"
                  class="rounded-tr-xl elevation-3 image--rounded"
                >
                  <ItemOwner :isOwner="product.company_owner === 'Y'" />
                </v-img>
                <v-card tile flat>
                  <v-card-title
                    class="pa-1 dark--text text-capitalize text-truncate"
                    >{{ product.name }}</v-card-title
                  >
                  <v-card-text
                    class="pa-1"
                    v-if="product.min_price || product.pricing"
                  >
                    {{ product.min_price || product.pricing[0].min_price }} KES
                    to
                    {{ product.max_price || product.pricing[0].max_price }} KES
                    per
                    {{ product.unit || product.unit_type.type | lowercase }}
                  </v-card-text>
                </v-card>
              </router-link>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="!filteredProducts.length">
          <v-col>
            <v-card-text class="text-center text-h6"
              >No products matching search</v-card-text
            >
          </v-col>
        </v-row>
        <Pagination
          v-if="pages.products && !searchProductQuery"
          :currentPage="pages.products.currentPage"
          :lastPage="pages.products.lastPage"
          :fetchNextFunction="fetchProducts"
        />
        <Pagination
          v-if="pages.searchedProducts && searchProductQuery"
          :currentPage="pages.searchedProducts.currentPage"
          :lastPage="pages.searchedProducts.lastPage"
          :fetchNextFunction="searchProducts"
          :searchTerm="searchProductQuery"
        />
      </v-card>

      <v-card flat class="py-12">
        <v-card-title class="text-h5 midnightGreen--text font-weight-bold"
          >Featured Companies</v-card-title
        >
        <v-card-text>
          <v-text-field
            v-model="searchCompanyQuery"
            @input="isTyping = true"
            label="Search companies"
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-card-text>
        <v-row v-if="queryCompanies.length">
          <v-col
            lg="4"
            md="6"
            sm="12"
            cols="12"
            class="px-2"
            v-for="company in queryCompanies"
            :key="company.id"
          >
            <v-card flat>
              <router-link
                :to="{
                  name: 'Company',
                  params: {
                    id: company.id,
                    redirectToRoute: '/dashboard'
                  }
                }"
              >
                <v-img
                  v-if="company.url"
                  :src="company.url"
                  height="15vh"
                  class="rounded-tr-xl elevation-3 image--rounded"
                >
                  <ItemOwner :isOwner="company.company_owner === 'Y'" />
                </v-img>
                <v-img
                  v-else
                  src="@/assets/company-placeholder.svg"
                  height="15vh"
                  class="rounded-tr-xl elevation-3 image--rounded"
                >
                  <ItemOwner :isOwner="company.company_owner === 'Y'" />
                </v-img>
                <v-card tile flat>
                  <v-card-title
                    class="pa-1 dark--text text-capitalize text-truncate"
                    >{{ company.name | capitalize }}</v-card-title
                  >
                  <v-card-text class="pa-1">{{ company.type }}</v-card-text>
                </v-card>
                <v-card
                  tile
                  flat
                  class="px-2 py-1 d-flex align-center justify-space-between"
                >
                  <v-chip
                    small
                    color="secondaryNeutral"
                    text-color="midnightGreen"
                    label
                    class="text-uppercase"
                    >{{ company.physical_location }}</v-chip
                  >
                </v-card>
              </router-link>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="!queryCompanies.length">
          <v-col>
            <v-card-text class="text-center text-h6"
              >No companies matching search</v-card-text
            >
          </v-col>
        </v-row>
        <Pagination
          v-if="pages.companies && !searchCompanyQuery"
          :currentPage="pages.companies.currentPage"
          :lastPage="pages.companies.lastPage"
          :fetchNextFunction="fetchCompanies"
        />
        <Pagination
          v-if="pages.searchedCompanies && searchCompanyQuery"
          :currentPage="pages.searchedCompanies.currentPage"
          :lastPage="pages.searchedCompanies.lastPage"
          :fetchNextFunction="searchCompanies"
          :searchTerm="searchCompanyQuery"
        />
      </v-card>
    </v-card>
    <v-card flat v-else>
      <v-card flat align="center">
        <v-card-title class="text-h5 midnightGreen--text font-weight-bold"
          >Featured Products</v-card-title
        >
        <hr />
        <v-card flat class="px-10">
          <v-img width="50%" src="@/assets/noProducts.png" />
        </v-card>
        <v-card-text class="text-h5 font-weight-bold midnightGreen--text pt-5"
          >No products yet...</v-card-text
        >
      </v-card>
      <v-card flat align="center" class="pt-5">
        <v-card-title class="text-h5 midnightGreen--text font-weight-bold"
          >Featured Companies</v-card-title
        >
        <hr />
        <NoItems :isPopulated="!companies.length" items
          >No companies yet...</NoItems
        >
      </v-card>
    </v-card>
  </Card>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";

import Card from "@/components/Card";
import NoItems from "@/components/NoItems";
import Pagination from "@/components/Pagination";
import ItemOwner from "@/components/ItemOwner";

export default {
  name: "Dashboard",
  components: {
    Card,
    NoItems,
    Pagination,
    ItemOwner
  },
  data() {
    return {
      searchProductQuery: "",
      searchCompanyQuery: "",
      selectedCategory: 0,
      isTyping: false
    };
  },
  async created() {
    await this.setIsLoading();
    await this.fetchProducts();
    if (this.token) {
      await this.fetchMyCompanies();
    }
    await this.fetchCompanies();
    await this.setLoaded();
    this.resetRedirectRoutes();
  },
  watch: {
    isTyping(typing) {
      if (typing) {
        setTimeout(() => {
          this.isTyping = false;

          if (this.searchProductQuery) {
            this.initiateSearchProducts();
          }

          if (this.searchCompanyQuery) {
            this.initiateSearchCompanies();
          }
        }, 500);
      }
    }
  },
  computed: {
    ...mapState([
      "token",
      "companies",
      "myCompanies",
      "products",
      "categories",
      "pages",
      "searchedProducts",
      "searchedCompanies",
      "searchedCategories"
    ]),
    ...mapGetters(["isLoggedIn"]),
    preferredCategory() {
      if (this.searchProductQuery && this.searchedCategories.length) {
        return this.searchedCategories;
      }

      return this.categories;
    },
    filteredProducts() {
      let category;

      if (this.categories[this.selectedCategory]) {
        category = this.categories[this.selectedCategory].name;
      }

      if (this.searchedCategories.length) {
        category = this.searchedCategories[this.selectedCategory].name;
      }

      if (category && category === "All") {
        return this.queryProducts;
      }

      const products = this.queryProducts;
      return products.filter(product => product.category === category);
    },
    queryProducts() {
      if (this.searchProductQuery && this.searchedProducts.length) {
        return this.searchedProducts;
      }

      if (this.searchProductQuery && !this.searchedProducts.length) {
        return [];
      }

      return this.products;
    },
    queryCompanies() {
      if (this.searchCompanyQuery && this.searchedCompanies.length) {
        return this.searchedCompanies;
      }

      if (this.searchCompanyQuery && !this.searchedCompanies.length) {
        return [];
      }

      return this.companies;
    }
  },
  methods: {
    ...mapActions([
      "fetchProducts",
      "setCurrentUserUuid",
      "fetchCompanies",
      "fetchMyCompanies",
      "searchProducts",
      "searchCompanies"
    ]),
    ...mapMutations(["setIsLoading", "setLoaded", "resetRedirectRoutes"]),
    toRoute(productId, companyId, filteredProducts) {
      const [product] = filteredProducts.filter(
        product => product.id === productId
      );

      const company = this.myCompanies.filter(
        company => company.id === product.company_id
      );

      const routeToProductPage = {
        name: "Product",
        params: {
          id: productId,
          redirectToRoute: "/dashboard"
        },
        query: {
          company: companyId
        }
      };

      const expressRoute = {
        path: "/interest",
        query: {
          company: companyId,
          product: productId
        }
      };

      if (company?.length) {
        return routeToProductPage;
      }

      return expressRoute;
    },
    initiateSearchProducts() {
      if (!this.isTyping) {
        setTimeout(() => {
          this.searchProducts({ query: this.searchProductQuery });
        }, 800);
      }
    },
    initiateSearchCompanies() {
      if (!this.isTyping) {
        setTimeout(() => {
          this.searchCompanies({ query: this.searchCompanyQuery });
        }, 800);
      }
    }
  }
};
</script>

<style scoped>
.image--rounded {
  position: relative;
}

.icon--owner {
  position: absolute;
  z-index: 2;
  bottom: 0;
  right: 0;
  background-color: #2b2d42;
  border-top-left-radius: 0.4rem;
}
</style>
